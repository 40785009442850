import { CSSObject, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonDepositsAvailableProps: CustomButtonProps = {
  variant: 'secondary',
  _hover: {
    bg: 'blue.400',
    _after: {
      opacity: 0,
    },
  },
  _active: {
    bg: 'blue.600',
    _after: {
      opacity: 0,
    },
  },
  _disabled: {
    bg: 'blue.700',
    pointerEvents: 'none',
  },
};

export const DepositContainer: CSSObject = {
  mb: '2',
};

export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};

export const RowDivider: CSSObject = {
  borderColor: 'whiteAlpha.300',
  borderStyle: 'dashed',
};

export const FlexWrapper: CSSObject = {
  py: '3',

  '.chakra-radio': {
    mr: 'auto',
  },
};

export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};

export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};

export const BoxCardsContainer: CSSObject = {
  mb: '2',
};

export const BoxInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, gamma.200, gamma.300)',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, gamma.200, gamma.300)',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    bg: 'beta.700',
    border: 'none',
    m: '2px',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
      color: 'white',
    },
    _hover: {
      bg: 'beta.400',
      border: 'none',
    },
    _focus: {
      bgGradient: 'linear(to-b, beta.800, beta.500)',
      boxShadow: 'none',
      border: '2px solid',
      borderColor: 'gamma.300',
      m: '0',
      h: '42px',
    },
  },
};

export const IncreaseAmountContainer: CSSObject = {
  mb: '2',
};

export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};

export const RadioCard: CSSObject = {
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    borderColor: 'white',
    _hover: {
      bg: 'blue.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};

export const bannerHeaderProps: TextProps = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'beta.800',
};

export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  color: 'beta.800',
  mt: '0.5',
};

export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  pt: '1.5',
};
