import * as puntgenie from './BetCardInput.styles.puntgenie';
import * as templebet from './BetCardInput.styles.templebet';
import * as slambet from './BetCardInput.styles.slambet';
import * as goldenbet888 from './BetCardInput.styles.goldenbet888';
import * as oldgill from './BetCardInput.styles.oldgill';
import * as mega777 from './BetCardInput.styles.mega777';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  puntgenie,
  templebet,
  slambet,
  goldenbet888,
  oldgill,
  mega777
};
