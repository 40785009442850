import React from 'react';
import { Global } from '@emotion/react';
import top from '@/assets/titanbet/images/background/bg.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${top}), linear-gradient(180deg, var(--bc-colors-beta-800), var(--bc-colors-beta-500))`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        },
      }}
    />
  );
}
