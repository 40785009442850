import { CSSObject } from '@chakra-ui/react';

export const SideNavHeading: CSSObject = {
  textTransform: 'uppercase',
  color: ['beta.500', null, null, 'white'],
  border: 'none',
};

export const SideNavLink: CSSObject = {
  textTransform: 'uppercase',
};

export const FlexSideLinkWrapper: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
