import { CSSObject, TextProps } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'medium',
  bg: 'orange.500',
  p: '1',
  borderRadius: 'base',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  mt: '1.5',

  sx: { svg: { top: 0 } },
};

export const FormLabelThemed: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'semibold',
  mt: '2',
};

export const FormControlGroup: CSSObject = {
  'input#email, input#residential-input': {
    borderRadius: 'md',
    bg: 'blackAlpha.500',
    color: 'gray.700',
    _hover: {
      bg: 'blackAlpha.600',
    },
    _focus: {
      bg: 'blackAlpha.600',
    },
    _placeholder: {
      color: 'white',
    },
  },
  // 'div.chakra-input__group > input[data-cy="field-x"]': {
  //   bg: 'blackAlpha.600',
  //   boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
  //   borderRadius: 'md',
  //   border: 'none',
  //   textShadow: 'none',
  //   _focus: {
  //     color: 'beta.500',
  //   },
  // },
};
