import * as goldenrush from './IncreaseAmountButton.styles.goldenrush';
import * as juicybet from './IncreaseAmountButton.styles.juicybet';
import * as vikingbet from './IncreaseAmountButton.styles.vikingbet';
import * as fiestabet from './IncreaseAmountButton.styles.fiestabet';
import * as oldgill from './IncreaseAmountButton.styles.oldgill';
import * as sugarcastle from './IncreaseAmountButton.styles.sugarcastle';
import * as buffalobet from './IncreaseAmountButton.styles.buffalobet';
import * as titanbet from './IncreaseAmountButton.styles.titanbet';
import * as slambet from './IncreaseAmountButton.styles.slambet';
import * as gigabet from './IncreaseAmountButton.styles.gigabet';
import * as chromabet from './IncreaseAmountButton.styles.chromabet';
import * as templebet from './IncreaseAmountButton.styles.templebet';
import * as mega777 from './IncreaseAmountButton.styles.mega777';

import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  goldenrush,
  juicybet,
  vikingbet,
  fiestabet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  chromabet,
  templebet,
  mega777,
};
