import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '1',
};

export const Subheading: CSSObject = {
  color: 'white',
};

export const DividerWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};

export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};

export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};

export const ButtonWithdrawal: CSSObject = {
  variant: 'secondary',
  fontWeight: 'normal',
};

export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
};

export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
};

export const AmountInputWrapperWithdrawal: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, gamma.200, gamma.300)',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, gamma.200, gamma.300)',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    bg: 'beta.700',
    border: 'none',
    m: '2px',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'beta.400',
      border: 'none',
    },
    _focus: {
      bgGradient: 'linear(to-b, beta.800, beta.500)',
      boxShadow: 'none',
      border: '2px solid',
      borderColor: 'gamma.300',
      m: '0',
      h: '42px',
    },
  },
};

/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};

export const BankRadio: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'gamma.800',
    border: '3px solid',
    borderColor: 'white',
    _hover: {
      bg: 'gamma.800',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};

export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};

export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};

export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};

/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '0',
  p: '0',
  w: '100%',
};

export const ListContainerHeading: CSSObject = {
  color: 'gamma.300',
  fontFamily: 'accent',
  fontSize: 'lg',
  mt: '3',
  '&&': {
    fontWeight: 'normal',
  },
};

/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};

export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};

export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  borderRadius: 'base',
  bg: status === EWithdrawalStatus.Pending && 'gamma.200',
  color: status === EWithdrawalStatus.Pending && 'blackAlpha.700',
  textTransform: 'uppercase',
  fontSize: '2xs',
});

export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'gray.300',
  fontSize: 'sm',
  fontWeight: 'semibold',
};

export const IncreaseAmountWrapper: CSSObject = {
  mb: '3',
};

export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '2',
  variant: 'solid',
  fontWeight: 'normal',
  pt: '1.5',
};

export const ButtonWithdrawalCardCancel: CSSObject = {
  fontWeight: 'normal',
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.200',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  boxShadow: 'unset',
  _hover: {
    bg: 'blackAlpha.500',
  },
  _active: {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 0px 14px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.300',
    border: 'none',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    border: 'none',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },
};

export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};

export const WithdrawalWrapper: CSSObject = {
  border: 'none',
  p: '2',
  bgGradient: 'linear(to-b, beta.600, beta.400)',
  boxShadow:
    '0px 0px 0px 1px #4A5454 inset,0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
};

export const WithdrawalCardContent: CSSObject = {
  color: 'white',
};

export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
};

export const WithdrawalCardDetailRequested: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};

export const InputWithdrawal: CSSObject = {
  '&&': {
    bg: 'beta.800',
    border: 'none',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
      color: 'white',
    },
    boxShadow: 'none',
    _hover: {
      bg: 'beta.500',
      border: 'none',
    },
    _focus: {
      bg: 'beta.500',
      border: '2px solid',
      borderColor: 'alpha.400',
      boxShadow: 'none',
      m: '0',
      h: '42px',
    },
  },
};
