import { CSSObject } from '@chakra-ui/react';
import HORSE from '@/assets/mega777/images/switch/HORSE.png';
import BALL from '@/assets/mega777/images/switch/BALL.png';

export const FlexWrapper = {
  borderRadius: 'md',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mx: 'unset',
  gap: '2',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  bg: 'rgba(129, 83, 255, 0.50)',
  h: '16',
  pos: 'relative',
  backdropFilter: 'blur(2.5px)',
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  fontSize: 'lg',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 8px rgba(251, 201, 21, 0.50)'
    : '0px 0px 8px rgba(67, 187, 255, 0.50)',
  color: isActive ? 'beta.500' : 'white',
  letterSpacing: 'widest',
  px: '3',
});

export const Switcher = (): CSSObject => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-3))',
  '--switch-track-width': '46px',
  bg: 'transparent',
  pos: 'relative',

  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    width: '100%',

    _before: {
      bgImg: `url(${HORSE})`,
      boxSize: '12',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      w: '50px',
      h: '100%',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&[data-checked]': {
      transform: 'none',
      _before: {
        bgImg: `url(${BALL})`,
      },
    },
  },
  '.chakra-switch__track': {
    borderRadius: 'md',
    border: '2px solid var(--grad-pink-2, #FF5887)',
    w: '70px',
    background: 'rgba(0, 0, 0, 0.20)',
    boxShadow: '0px 5px 6px 0px rgba(0, 0, 0, 0.30)',

    _before: {
      h: '7px',
      w: '60px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRadius: '2em',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
      content: '""',
      pos: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
});
