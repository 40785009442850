import { CustomButtonProps } from '@/components/Button/Button';

export const buttonLogInProps: CustomButtonProps = {
  variant: 'solid',
  w: 'full',
  fontWeight: 'normal',
  borderRadius: '4px',
  background:
    'var(--gradients-gradient-gamma-300-500, linear-gradient(180deg, #FFD562 0%, #DEA913 100%)) !important',
  backgroundColor: 'red',
  color: 'alpha.700',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
};
