import { CSSObject } from '@chakra-ui/react';
import bg from '../../../assets/mega777/images/backgrounds/onboarding_bg.jpg';

export const WrapperFlexOnboarding: CSSObject = {
  bgImage: `url(${bg})`,
  bgSize: 'cover',
  backgroundBlendMode: 'overlay',
  bgRepeat: 'no-repeat',
  justifyContent: 'center',
  position: 'relative', // Ensure the position is set
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  maxH: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  borderRadius: [null, null, 'lg'],
  bg: 'alpha.900',
  boxShadow: [null, null, '0px 4px 30px 0px rgba(0, 0, 0, 0.40)'],
  backdropFilter: 'blur(2.5px)',
  pos: 'relative',
  w: ['100dvw', null, 'unset'],
  p: '1px',
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  my: ['0', null, 'auto'],
  maxW: ['auto', null, '460px'],
  h: '100%',
  bg: 'alpha.900',
  borderRightRadius: ['unset', null, 'lg'],
  '#email': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    color: 'white',
    fontWeight: 'normal',
    borderRadius: 'lg',
    h: '38px',
    border: 'none',
    ':hover, :focus': {
      bg: 'alpha.600',
    },
  },
};

export const Title: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  display: 'flex',
  mb: '4',
};

export const Input: CSSObject = {
  alignSelf: 'stretch',
  h: '38px',
  '&&&': {
    borderRadius: 'base',
    bg: 'var(--chakra-blackAlpha-500, rgba(0, 0, 0, 0.36))',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    border: 'none',
    color: 'white',
    fontWeight: 'normal',
    fontFamily: 'ProximaNova',
    '&&:hover, &&:focus': {
      bg: 'alpha.600',
    },

    '&&:active, &&:focus': {
      color: '#fff',
    },

    _placeholder: {
      color: 'white',
    },
  },
};
