import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  overflow: 'hidden',
  borderRadius: 'md',
  '.chakra-accordion__item': {
    mb: '2',
    borderTop: 'none',
    bg: 'alpha.900',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
    borderRadius: 'md',
    px: '0',
  },
  '.chakra-accordion__panel': {
    color: 'white',
    borderTop: '2px solid',
    borderTopColor: 'whiteAlpha.200',
    pt: '3',
    '.chakra-checkbox__label': {
      fontWeight: 'bold',
    },
    '.chakra-stack': {
      mb: '0',
    },
    '.chakra-text': {
      _first: {
        color: 'white',
      },
      _notFirst: {
        borderRadius: 'base',
        bg: 'alpha.800',
        color: 'whiteAlpha.700',
        opacity: '0.5',
        border: 'none',
      },
    },
  },
};
export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  '.chakra-accordion__button': {
    borderBottom: 'none',
    alignItems: 'center',
    pl: '10px',
    'svg[class*="StyledIconBase"]': {
      display: 'none',
    },
    '&[aria-expanded="false"]': {
      borderBottom: 'none',
    },
    _hover: {
      bg: 'none',
    },
  },
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'white',
  },
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
};
export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    borderRadius: 'xs',
    border: '1px solid black',
  },
};
export const AccordionHeaderLogOut: CSSObject = {
  bg: 'alpha.900',
  border: 'none',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: 'md',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  mt: '-2',
  marginLeft: '0',
  marginRight: '0',
  px: '3',
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
export const IconPerson: CSSObject = {
  display: 'none',
};
export const TextAccordion: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'Staatliches',
  mr: 'auto',
  mt: '0.5',
};
export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'alpha.100',
  borderRadius: 'base',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  fontSize: 'xs',
  fontWeight: 'bold',
  gap: '1',
  p: '2',
  '.chakra-text': {
    color: 'var(--bc-colors-alpha-700) !important',
    fontWeight: 'normal',
  },
  '.chakra-icon': {
    color: 'alpha.700',
  },
  mb: '2',
};
export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'white',
  },
  border: 'none',
  bg: 'alpha.500',
  mb: '0',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};
