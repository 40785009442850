import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'beta.400';
      }
      if (state === 'ended') {
        return 'red.500';
      }
      // 'prior'
      return 'alpha.200';
    },
    color: () => {
      if (state === 'ended') {
        return 'blackAlpha.700';
      }
      // 'prior'
      return 'blackAlpha.700';
    },
    boxShadow:
      state === 'ended'
        ? '0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
        : '0px 2px 3px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    px: 1,
    fontFamily: 'accent',
    fontWeight: 'bold',
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '15px',
  },
});
