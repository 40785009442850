import * as betprofessor from './SideNavLink.styles.betprofessor';
import * as puntcity from './SideNavLink.styles.puntcity';
import * as sterlingparker from './SideNavLink.styles.sterlingparker';
import * as vikingbet from './SideNavLink.styles.vikingbet';
import * as volcanobet from './SideNavLink.styles.volcanobet';
import * as goldenrush from './SideNavLink.styles.goldenrush';
import * as juicybet from './SideNavLink.styles.juicybet';
import * as forsport from './SideNavLink.styles.forsport';
import * as templebet from './SideNavLink.styles.templebet';
import * as goldenbet888 from './SideNavLink.styles.goldenbet888';
import * as chromabet from './SideNavLink.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  puntcity,
  sterlingparker,
  vikingbet,
  volcanobet,
  goldenrush,
  forsport,
  juicybet,
  templebet,
  goldenbet888,
  chromabet,
};
