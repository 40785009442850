import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import HORSE from '@/assets/mega777/images/switch/HORSE.png';
import BALL from '@/assets/mega777/images/switch/BALL.png';
import IMAGE_RIGHT from '@/assets/mega777/images/switch/image-right.png';
import IMAGE_LEFT from '@/assets/mega777/images/switch/image-left.png';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    mx: ['1.5', null, 'unset'],
    mb: 2,
    gap: '2',
    pos: 'relative',
  }),
});

const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
    fontSize: 'lg',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 0px 8px rgba(251, 201, 21, 0.50)'
      : '0px 0px 8px rgba(67, 187, 255, 0.50)',
    color: isActive ? 'beta.500' : 'white',
    letterSpacing: 'widest',
    px: '0',
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  label: 'desktopToggle-Switcher',
  baseStyle: () => ({
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-3))',
    '--switch-track-width': '46px',
    bg: 'transparent',
    pos: 'relative',

    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      width: '100%',
      h: '100%',

      _before: {
        bgImg: `url(${HORSE})`,
        boxSize: '12',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        w: '90px',
        h: '100%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
      '&[data-checked]': {
        transform: 'none',

        _before: {
          bgImg: `url(${BALL})`,
        },
      },
    },
    '.chakra-switch__track': {
      borderRadius: 'md',
      border: '2px solid var(--grad-pink-2, #FF5887)',
      w: '80px',
      h: '28px',
      background: 'rgba(0, 0, 0, 0.20)',
      boxShadow: '0px 5px 6px 0px rgba(0, 0, 0, 0.30)',

      _before: {
        h: '7px',
        w: '20px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        borderRadius: '2em',
        bgImg: `url(${IMAGE_LEFT})`,
        content: '""',
        pos: 'absolute',
        left: '8px',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
      _after: {
        h: '7px',
        w: '20px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        borderRadius: '2em',
        bgImg: `url(${IMAGE_RIGHT})`,
        content: '""',
        pos: 'absolute',
        right: '-12px',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>
      {/* Todo: build a new component */}
      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </TextToggle>
    </FlexWrapper>
  );
}
