import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'solid',
    boxSize: '34px',
    minWidth: '34px',
    minHeight: '34px',
    color: 'gamma.400',
    borderRadius: 'base',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
          bg: 'red'
        },
        boxShadow: '0px 0px 8px 0px #FFD56280',
      },
      '&[data-closed="true"]:not(&[data-active="true"])': {
        bg: 'transparent',
        border: '2px solid',
        borderColor: 'whiteAlpha.300',
        color: 'white',
        textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
        boxShadow: 'none',
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  p: '4px',
  borderRadius: 'base',
  color: 'gamma.200',
  flexDirection: 'row',
};

export const TextRaceConditions: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  span: {
    fontWeight: 'bold'
  },
  _after: {
    content: `""`,
    w: '1px',
    h: '4',
    bg: 'whiteAlpha.200',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'white',
  bg: 'green.500',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
};

export const RaceStatusText: CSSObject = {
  color: 'orange.800',
  bg: 'orange.400',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'white',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: 'normal',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  m: '0',
  py: '3',
};

export const RaceRunnerList: CSSObject = {
  bg: 'transparent',
  p: '0',
};

export const TextTitle: CSSObject = {
  fontFamily: 'Staatliches',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xl',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: 'white',
  letterSpacing: '1px',
};

export const RaceName: CSSObject = {
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
  color: 'white',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  mt: '1',
  pb: '1.5',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  '.chakra-switch__track': {
    background: 'alpha.700',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
    '& .chakra-switch__thumb': {
      background: 'white',
      '&[data-checked]': {
        bg: 'alpha.200',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
      },
    },
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
  pb: '2',
  alignItems: 'center',
  fontFamily: 'accent'
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '12',
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  mb: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};
