import { CSSObject } from '@chakra-ui/react';

export const wrapperProps: CSSObject = {
  bg: 'alpha.900',
  h: '100%',
  minW: '460px',
  display: 'flex',
  justifyContent: 'center',
  borderRightRadius: ['unset', null, 'lg'],
  color: 'white',

  sx: {
    'div:first-child': {
      display: 'flex',
      flexDir: 'column',
      fontSize: 'xs',
      fontWeight: 'medium',
      alignItems: 'flex-start',
      svg: {
        boxSize: '2.5',
        mr: '1',
        mt: '-1px',
      },
      '.chakra-link': {
        mb: '4',
        color: 'gamma.300',
      },
      p: {
        color: 'white',
        fontWeight: 'normal',
      },
    },
  },
};

export const TextForgotPasswordSuccess: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'medium',
  mb: '2',

  '+ a': {
    display: 'none',
  },
};

export const linkLoginProps: CSSObject = {
  color: 'gamma.300',
};

export const ForgotPasswordButton: CSSObject = {
  fontWeight: 'normal',
  color: 'alpha.800',
  background:
    'var(--gradients-gradient-gamma-300-500, linear-gradient(180deg, #FFD562 0%, #DEA913 100%))',
};
