import * as betroyale from './Deposit.styles.betroyale';
import * as fiestabet from './Deposit.styles.fiestabet';
import * as puntcity from './Deposit.styles.puntcity';
import * as puntgenie from './Deposit.styles.puntgenie';
import * as questbet from './Deposit.styles.questbet';
import * as vikingbet from './Deposit.styles.vikingbet';
import * as volcanobet from './Deposit.styles.volcanobet';
import * as oldgill from './Deposit.styles.oldgill';
import * as titanbet from './Deposit.styles.titanbet';
import * as slambet from './Deposit.styles.slambet';
import * as buffalobet from './Deposit.styles.buffalobet';
import * as templebet from './Deposit.styles.templebet';
import * as mega777 from './Deposit.styles.mega777';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  oldgill,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  vikingbet,
  volcanobet,
  titanbet,
  slambet,
  buffalobet,
  templebet,
  mega777,
};
