import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {
    w: '100%',
  },
  buttonCashOutDesc: {
    variant: 'secondary',
    w: '100%',
    height: '29px',
    fontWeight: 'normal',
    fontSize: 'sm',
    textTransform: 'capitalize',
    color: 'alpha.700',
    sx: {
      '.cashoutValue': {
        color: 'alpha.700',
      },
      '&:hover': {
        '.cashoutValue': {
          color: 'alpha.700',
        },
      },
      '&:active': {
        '.cashoutValue': {
          color: 'alpha.700',
        },
      },
    },
  },
  buttonCashOutAmount: {
    variant: 'solid',
    height: '29px',
    fontFamily: 'Staatliches',
    fontWeight: 'normal',
  },
  flexOddsBoostBanner: {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  },
  textOddsBoost: { color: 'gamma.400', fontFamily: 'Staatliches', pt: '0.5' },
  iconOddsBoost: {
    fill: 'gamma.400',
  },
  vstackBetsWrapper: {
    w: '100%',
    ml: 'inherit',
  },
  buttonGroupWrapper: {
    w: '100%',
  },
  iconCashOut: {
    fill: 'currentColor',
  },
  cashoutText: {
    color: 'currentColor',
    pt: '1',
  },
  cashoutValue: {
    lineHeight: 'normal',
    pt: '0.5',
  },
};
export const LinkVenueName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  ml: '26px',
};
export const RaceTime: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  alignSelf: 'flex-end',
  ml: '26px',
};
export const BonusBetCheckMark: CSSObject = {
  '&&': {
    display: 'none',
  },
};
export const BonusBetText: CSSObject = {
  color: 'gamma.400',
  fontSize: 'xs',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'Staatliches',
  bg: 'blackAlpha.500',
  borderRadius: 'md',
  boxShadow:
    '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  px: '2',
  py: '4px',
  lineHeight: 'normal',
};
export const BetReturn: CSSObject = {
  bg: 'blackAlpha.600',
  color: 'white',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontFamily: 'accent',
  fontSize: '2xs',
};
export const BoxBetReturnValue: CSSObject = {
  py: '0.5',
  p: '1',
  fontWeight: 'extrabold',
  color: 'gamma.400',
};
export const BoxBetReturnText: CSSObject = {
  my: '1',
  px: '1',
  borderRight: '1px solid',
  borderRightColor: 'whiteAlpha.300',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  border: '2px solid',
  borderColor: 'white',
  color: 'orange.500',
  bg: 'white',
};
