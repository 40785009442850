import * as betgalaxy from './DepositLimit.styles.betgalaxy';
import * as betprofessor from './DepositLimit.styles.betprofessor';
import * as betroyale from './DepositLimit.styles.betroyale';
import * as fiestabet from './DepositLimit.styles.fiestabet';
import * as puntcity from './DepositLimit.styles.puntcity';
import * as puntgenie from './DepositLimit.styles.puntgenie';
import * as questbet from './DepositLimit.styles.questbet';
import * as sterlingparker from './DepositLimit.styles.sterlingparker';
import * as vikingbet from './DepositLimit.styles.vikingbet';
import * as volcanobet from './DepositLimit.styles.volcanobet';
import * as wellbet from './DepositLimit.styles.wellbet';
import * as goldenrush from './DepositLimit.styles.goldenrush';
import * as juicybet from './DepositLimit.styles.juicybet';
import * as forsport from './DepositLimit.styles.forsport';
import * as junglebet from './DepositLimit.styles.junglebet';
import * as oldgill from './DepositLimit.styles.oldgill';
import * as sugarcastle from './DepositLimit.styles.sugarcastle';
import * as buffalobet from './DepositLimit.styles.buffalobet';
import * as titanbet from './DepositLimit.styles.titanbet';
import * as slambet from './DepositLimit.styles.slambet';
import * as gigabet from './DepositLimit.styles.gigabet';
import * as templebet from './DepositLimit.styles.templebet';
import * as goldenbet888 from './DepositLimit.styles.goldenbet888';
import * as mega777 from './DepositLimit.styles.mega777';
import * as chromabet from './DepositLimit.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  goldenrush,
  juicybet,
  forsport,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  templebet,
  goldenbet888,
  mega777,
  chromabet,
};
