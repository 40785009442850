import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
};
export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  textTransform: 'uppercase',
  mb: '1',
};
export const EachWayText: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
};
export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px solid',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
  'p:nth-child(1)': {
    fontSize: '2xs',
    fontWeight: 'bold',
    color: 'white',
  },
  'p:nth-child(2)': {
    color: 'gamma.400',
  },
};
export const ReviewStakeText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'white',
};
export const ReviewStake: CSSObject = {
  fontSize: 'sm',
  color: 'beta.400',
  fontWeight: 'black',
};
export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
};
export const ReviewEst: CSSObject = {
  fontSize: 'lg',
  color: 'gamma.400',
  fontWeight: 'black',
};
export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'bold',
  fontSize: 'md',
  fontFamily: 'accent',
  color: 'beta.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  bg: 'beta.400',
  border: 'none',
  borderLeftRadius: isPrefix ? 'base' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'base',
  h: '36px',
  mb: '0.5',
});
export const InputBetSlip: CSSObject = {
  border: '2px solid',
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'accent',
  fontWeight: 'bold',
  boxShadow: 'none',
  h: '38px',
  pl: '2.8rem',
  '&&&&': {
    ':first-child': {
      pl: '2',
    },
    borderRightRadius: 'md',
    borderLeft: '2px solid',
    borderColor: 'beta.400',
    borderLeftRadius: 'md',
    bg: 'beta.800',
    pl: '12',
  },
  _hover: {
    bg: 'beta.700',
    '&&': {
      boxShadow:
        '0px 0px 8px 0px rgba(173, 241, 6, 0.5), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
      borderRightRadius: 'md',
      borderColor: 'beta.400',
      borderLeftRadius: 'md',
      pl: '12',
    },
  },
  _focus: {
    bg: 'beta.900',
    '&&': {
      _placeholder: {
        color: 'beta.400',
      },
      color: 'beta.400',
      boxShadow: 'none',
      borderRightRadius: 'md',
      borderColor: 'beta.400',
      borderLeftRadius: 'md',
      pl: '12',
    },
  },
};
export const FlexiReview: CSSObject = {
  pl: '8',
};
export const FlexReviewExotic: CSSObject = {
  textAlign: 'left',
  "[class$='ReviewStake']": {
    color: 'gamma.400',
  },
};
