import React from 'react';
import { Box, BoxProps, CSSObject, Icon, StackProps } from '@chakra-ui/react';
import { CheckmarkOutline, CloseOutline } from 'styled-icons/evaicons-outline';
import {
  TPasswordRule,
  TPasswordRulesIcon,
  TPasswordTooltip,
} from '../services/SignUp.types';

export const SignUpDatePicker: CSSObject = {
  '.react-date-picker__calendar': {
    zIndex: 'popover',
  },
};

export const FlexHeaderWrapper: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontStyle: 'normal',
  fontWeight: 'normal',
  mb: '4',
  gap: '5px',
};

export const FormStack = (props: StackProps) => (
  <Box
    {...props}
    display={['unset', null, 'flex']}
    gap={['unset', null, '2']}
  />
);

export const SignUpInput: CSSObject = {
  borderRadius: 'base',
};

export const addressWrapperProps: BoxProps = {
  sx: {
    '& .chakra-form-control': {
      mb: '2',
    },
  },

  mb: '4',
};

export const SignupAcknowledgementButton: CSSObject = {
  color: 'gamma.300',
  fontWeight: 'bold',
  textDecoration: 'none',
};

export const BoxLoginCTAContainer: CSSObject = {
  display: 'none',
};

export const SignUpButton: CSSObject = {
  mb: 0,

  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset',
  bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  color: 'alpha.700',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  // boxShadow:
  //   '0px 0px 0px 1px #000 inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.50) inset',
  _hover: {
    // bg: 'beta.500',
    color: 'alpha.800',
  },
};

export const BoxManualAddressContainer: CSSObject = {
  p: '4',
  pb: '0',
  border: '1px dashed',
  borderColor: 'gray.300',
  borderRadius: 'base',
};

export const CheckboxHelpText: CSSObject = {
  color: 'white',
  button: {
    h: 'unset',
  },
};

export const PasswordTooltip = ({
  validPassword,
}: TPasswordTooltip): CSSObject => ({
  bg: validPassword ? 'green.500' : 'red.500',
  borderRadius: 'md',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
  color: 'white',
  '.chakra-tooltip__arrow': {
    // ! Importants are needed to override tooltip's inline styles
    bg: validPassword
      ? 'var(--bc-colors-green-500) !important'
      : 'var(--bc-colors-red-500) !important',
  },
});

export const PasswordRule = ({ active }: TPasswordRule): CSSObject => ({
  fontWeight: 'semibold',
  opacity: '1',
  color: active ? 'white' : 'white',
});

export const PasswordRulesIcon = ({ active }: TPasswordRulesIcon) => (
  <Icon as={active ? CheckmarkOutline : CloseOutline} boxSize="4" mr="0.5" />
);
