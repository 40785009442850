import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';
import background from '@/assets/titanbet/images/race-bg.png';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderBottomColor: 'blackAlpha.400',
  boxShadow: !isLast && '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  mt: '-0.5',
  bg: 'transparent',
  filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25))',
  _hover: {
    bg: 'blackAlpha.100',
  },
});

export const BoxNextToJumpContainer: CSSObject = {
  bgImage: `url(${background})`,
  mx: '0',
  borderRadius: 'lg',
  mb: '2',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25),0px 0px 0px 2px #C19262 inset, 0px 0px 0px 5px #1B1E1F inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
};

export const Icon: CSSObject = {
  boxSize: '8',
  color: 'beta.600',
  bgGradient: 'linear(to-b, alpha.200, alpha.300)',
  border: '2px',
  borderColor: 'beta.800',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px #706F6F inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.3) inset',
};

export const TextRace: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'bold',
};

export const TextNumber: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'gray.300',
  color: 'gray.600',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  py: '0',
  pt: '0.5',
  borderRadius: 'base', 
  '&&': {
    h: '18px',
    maxH: '18px',
    minH: '18px',
    lineHeight: '18px',
  },
};

export const BoxHeading: CSSObject = {
  display: 'none',
};
