import sideNavTop from '@/assets/titanbet/images/sideNav/sideNavTop.png';
import sideNavBot from '@/assets/titanbet/images/sideNav/sideNavBot.png';
import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    mt: ['0', null, null, '8'],
    mb: ['2', null, null, '14'],
  },
  boxWrapperProps: {
    position: [null, null, 'relative'],
    _before: {
      content: '""',
      display: ['none', null, null, 'block'],
      position: 'absolute',
      top: '-25px',
      left: '-17px',
      width: '306px',
      height: '66px',
      backgroundImage: ['none', null, null, `url(${sideNavTop})`],
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      zIndex: '1',
    },
    _after: {
      content: '""',
      display: ['none', null, null, 'block'],
      position: 'absolute',
      bottom: '-25px',
      left: '-17px',
      width: '306px',
      height: '66px',
      backgroundImage: ['none', null, null, `url(${sideNavBot})`],
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      zIndex: '1',
    },
  },
  boxNavigationWrapperProps: {
    overflowX: 'unset',
  },
  navigationBoxProps: {},
};
