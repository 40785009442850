import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';

export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};
export const BetCardContainer = (): CSSObject => ({
  bg: 'alpha.900',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(8px)',
  color: 'white',
  mb: '0',
  borderRadius: 'md',
  fontFamily: 'accent',
});
export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'white',
  },
};
export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'sm',
};
export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'white',
};
export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'gamma.300',
  fontWeight: 'bold',
  lineHeight: 'white',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
});
export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  lineHeight: 'normal',
  '&&': {
    color: 'white',
  },
};
export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  ml: '8',
};
export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};
export const FlexPlacedContainer: CSSObject = {
  pb: '2',
  px: '2',
  pt: '0',
};
export const BetPlaced: CSSObject = {
  color: 'gray.600',
  flexDirection: 'row',
  flexWrap: 'wrap',
  bg: 'transparent',
  px: '0',
  borderTop: '1px dashed rgba(0, 0, 0, 0.4)',
  boxShadow: '0px 1px 0px 0px inset rgba(255, 255, 255, 0.08)',
};
export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
};
export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const BetPropositionClosedButton: CSSObject = {
  bg: 'white',
  boxShadow: 'md',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
};
export const BetPlacedRow: CSSObject = {
  color: 'white',
  _odd: {
    flexBasis: '35%',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
  },
  _even: {
    justifyContent: 'flex-start',
    flexBasis: '65%',
    fontWeight: 'medium',
    textAlign: 'left',
  },
};
export const TextPlacedLabel: CSSObject = {
  flex: '1',
};
export const BetCardSVG = (header: boolean) => ({
  color: header ? 'beta.300' : 'white',
  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});
export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
  py: '0',
});
export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);
export const EventRule: CSSObject = {
  color: 'white',
};
export const BetPropositionClosedError: CSSObject = {
  bg: 'blackAlpha.700',
  borderRadius: 'md',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};
export const BetOdds: CSSObject = {
  mb: '2px',
};
export const buttonBetPropositionClosedProps: CSSObject = {
  variant: 'unstyled',
  bg: 'orange.500',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
  borderRadius: 'base',
  color: 'white',
  fontWeight: 'bold',
  fontSize: 'xs',
  px: '3',
  py: '2',
  paddingLeft: '12px',
  paddingRight: '12px',
};
export const DefaultStakeFlexbox: CSSObject = {
  gap: '1',
};
export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
  pr: '2',
  pl: '10',
};
export const BetCardVStack: CSSObject = {
  pb: '2',
};
// THEMING MYSTERY BET
export const mysteryBetFooterPrimaryContainerWrapperProps: CSSObject = {
  w: '95%',
  bg: 'alpha.800',
  borderRadius: '5px',
  boxShadow: 'md',
  color: 'beta.500',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2',
  p: '2',
  mt: '1',
  m: '3',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const mysteryBetFooterSecondaryContainerWrapperProps: CSSObject = {
  w: '95%',
  bg: 'alpha.800',
  borderRadius: '5px',
  boxShadow: 'md',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '2',
  p: '2',
  mt: '1',
  m: '3',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const ExoticsNumberContainer: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  minW: '25px',
};
