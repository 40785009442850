import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/titanbet/icons/mysteryLogo.png';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 2,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: { variant: 'primary' },
  imageLogo: {
    w: '272px',
    src: mysteryLogo,
  },
  iconMystery: {},
  buttonMysteryLabel: {
    color: 'white',
    gap: '2',
    bg: 'blackAlpha.600',
    bgGradient: 'none',
    borderRadius: 'base',
    p: '3',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    fontFamily: 'body',
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.600',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    px: '3',
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
  },
  buttonGroupAddToBetslip: {
    mr: '1',
    alignItems: 'center',
  },
  flexWrapperMysteryPlacedBetView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'fit-content',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.600',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '2',
  },
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.500',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.600',
    borderRadius: 'base',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear-gradient(to bottom, alpha.200 0%, alpha.300 100%)',
    borderRadius: 'md',
    boxSize: '10',
    p: '2',
    color: 'beta.700',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
    cursor: 'pointer',
  },
  imageMysteryBet: {
    w: '5',
    color: 'red',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    color: 'white',
    fontWeight: 'bold',
  },
  textPriceInfo: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    color: 'gamma.300',
    fontWeight: 'bold',
  },
  textRollover: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    color: 'gamma.300',
    fontWeight: 'bold',
  },
  textError: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: '10',
    color: 'gamma.300',
  },
  iconRace: { color: 'alpha.300' },
  textSpanWhite: {
    color: 'white',
  },
  buttonRollOver: {
    variant: 'primary',
    h: '7',
    _disabled: {
      bg: 'blackAlpha.600',
      color: 'gamma.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    },
  },
  textSlash: {
    color: 'whiteAlpha.400',
  },
};
