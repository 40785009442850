import { themeName } from '@/helpers/getThemeConfig';
import * as buffalobet from './Logo.styles.buffalobet';
import * as mega777 from './Logo.styles.mega777';

const styleImports: Partial<Record<string, any>> = {
  buffalobet,
  mega777,
};

export const styleImport = styleImports[themeName]?.default;
