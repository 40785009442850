import * as betprofessor from './Modal.styles.betprofessor';
import * as fiestabet from './Modal.styles.fiestabet';
import * as goldenrush from './Modal.styles.goldenrush';
import * as juicybet from './Modal.styles.juicybet';
import * as puntcity from './Modal.styles.puntcity';
import * as puntgenie from './Modal.styles.puntgenie';
import * as sterlingparker from './Modal.styles.sterlingparker';
import * as vikingbet from './Modal.styles.vikingbet';
import * as volcanobet from './Modal.styles.volcanobet';
import * as oldgill from './Modal.styles.oldgill';
import * as sugarcastle from './Modal.styles.sugarcastle';
import * as buffalobet from './Modal.styles.buffalobet';
import * as titanbet from './Modal.styles.titanbet';
import * as slambet from './Modal.styles.slambet';
import * as gigabet from './Modal.styles.gigabet';
import * as templebet from './Modal.styles.templebet';
import * as goldenbet888 from './Modal.styles.goldenbet888';
import * as mega777 from './Modal.styles.mega777';
import * as chromabet from './Modal.styles.chromabet';

import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  sterlingparker,
  vikingbet,
  volcanobet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  templebet,
  goldenbet888,
  mega777,
  chromabet,
};
