import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  px: '2',
  my: ['0', null, '4'],
  mb: ['2', null, 'unset'],
  mt: 'unset',
  borderRadius: 'lg',
  bg: 'beta.700',
  border: '2px solid',
  borderColor: 'gamma.500',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
};

export const BoxWrapper: CSSObject = {
  pt: '2.5',
  px: '0.5',
};

export const TextHeading: CSSObject = {
  color: 'gamma.300',
  fontSize: 'md',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'capitalize',
};

export const ButtonViewMore: CSSObject = {
  bg: 'beta.400',
  mb: '2.5',
  textAlign: 'center',
  fontSize: 'sm',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
  color: 'beta.800',
  bgGradient: 'linear(to-b, gamma.300, gamma.400)',
  border: '2px solid',
  borderColor: '#FFD69E',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #BD873E inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.3) inset',
  textTransform: 'capitalize',
  _hover: {
    bgGradient: 'linear(to-b, yellow.600, yellow.600)',
  },
};
