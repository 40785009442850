import { PropositionButtonSchema } from '@/views/sports/components/PropositionButton/styles/PropositionButton.styles';

export const propositionButtonStyles: PropositionButtonSchema = {
  buttonBase: {
    variant: 'primary',
  },
  buttonBaseSpanProps: {
    overflow: 'hidden',
    pos: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    textTransform: 'uppercase',
    fontWeight: 'extrabold',
    fontFamily: 'body',
    fontSize: '8px',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  },
  buttonBaseBox: {
    fontSize: 'xs',
    fontWeight: 'black',
    fontFamily: 'body',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  },
  textBase: {
    textAlign: 'center',
    color: 'beta.600',
  },
};
