import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['3', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};
export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  bg: ['blackAlpha.400', null, 'rgba(114, 68, 238, 1)'],
  boxShadow: [
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    null,
    null,
    '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  ],
  borderRadius: [0, '8px'],
  px: ['10px', 0],
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};
export const NavLink = (isActive: boolean): CSSObject => ({
  bg: ['unset', null, isActive ? 'blackAlpha.500' : 'rgba(114, 68, 238, 1)'],
  color: isActive ? 'gamma.400' : 'white',
  textShadow: isActive
    ? 'unset'
    : ['unset', null, '0px 2px 4px rgba(0, 0, 0, 0.25)'],
  textTransform: 'uppercase',
  fontWeight: ['normal', null, 'bold'],
  fontSize: ['sm', null, '11px'],
  lineHeight: 'normal',
  py: ['2', '3'],
  pl: isActive ? ['2', '3'] : ['2', '4'],
  pr: ['2', '4'],
  fontFamily: ['Staatliches', null, 'accent'],
  borderBottom: [
    isActive && '2px solid',
    null,
    isActive ? '2px solid' : '1px solid',
  ],
  borderBottomColor: [null, null, isActive ? 'gamma.400' : 'blackAlpha.300'],
  borderTop: [null, null, '1px solid'],
  borderTopColor: [null, null, isActive ? 'transparent' : 'whiteAlpha.200'],
  _first: {
    borderTop: 'none',
    borderTopRadius: ['none', null, '8px'],
  },
  _last: {
    borderBottomRadius: '8px',
    borderBottom: 'none',
  },
  '&:hover, &:focus': {
    bg: !isActive && 'blackAlpha.300',
  },
});

export const TopNavLabelWrapper: CSSObject = {
  mx: '2',
  color: 'white',
  fontFamily: 'accent',
};
