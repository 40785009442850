import { CSSObject } from '@chakra-ui/react';

export const BoxPageWrapper: CSSObject = {
  px: '0',
};

export const BoxSectionWrapper: CSSObject = {
  mb: ['2', '3.5'],
  px: ['2', 0],

  _last: {
    a: {
      _last: { borderBottom: [0, 'unset'] },
    },
  },
};

export const BoxSportWrapper: CSSObject = {
  borderColor: 'transparent',
  borderRadius: 'base',
  border: 'none',
  bg: 'transparent',
};

export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'lg',
  fontFamily: 'body',
  fontWeight: 'bold',
};

export const LinkItem: CSSObject = {
  color: 'white',
  bg: 'beta.200',
  border: 'none',
  borderRadius: 'md',
  textTransform: 'uppercase',
  h: '10',
  mb: ['1', '2'],
  _last: { mb: '0' },

  _hover: { bg: 'beta.300' },
};

export const IconSport: CSSObject = {
  color: 'gamma.300',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};

export const IconChevron: CSSObject = {
  boxSize: '5',
  border: 'none',
  p: '1',
  color: 'gamma.300',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};

export const TextName: CSSObject = {
  fontFamily: 'body',
  fontSize: 'sm',
  fontWeight: 'bold',
};
