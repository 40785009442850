import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  textTransform: 'uppercase',
  color: 'yellow.900',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};

export const SelectionPopupContainer: CSSObject = {
  zIndex: 'docked',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  bg: 'beta.700',
  borderBottom: '1px dashed',
  borderColor: 'white',

  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    },
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-7px',
  width: '24px',
  height: '24px',
  color: 'white',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  mt: '-3px',
};
