import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  p: 0,
  color: 'gamma.300',
  bg: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  w: 4,
  h: 4
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'gamma.300',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  bg: 'beta.200',
  px: '3',
  alignItems: 'center',
  gridTemplateColumns: '32px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  boxShadow:
    '0px 0px 0px 1px rgba(74, 84, 84, 1) inset, 0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'md',
  w: '100%',
  h: '64px',
  _hover: {
    bg: 'beta.300',
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'sm',
  color: 'gamma.300',
  mt: '1.5',
  fontFamily: 'accent',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'alpha.100',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'beta.500',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'alpha.100',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'alpha.100',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'gamma.300',
  border: '2px',
  borderColor: 'gamma.300',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
