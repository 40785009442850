import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonShowMoreProps: CustomButtonProps = {
  variant: 'solid',
  pt: '1',
};

export const FlexBetCard: CSSObject = {
  rowGap: '2',
  w: '100%',
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};
