import { HomeLinksSchema } from './HomeLinks.styles';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: ['center', null, null, 'flex-start'],
    my: '3',
    mx: 'auto',
    w: '100%',
    maxW: ['100dvw', null, '100%'],
    overflowX: ['scroll', null, 'auto'],
    overflowY: 'hidden',
    gap: 1,
    sx: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '.chakra-linkbox': {
        gap: '1',
        h: '50px',
        minW: '98px',
        overflow: 'hidden',
        borderRadius: 'md',
        boxShadow:
          '0px 4px 4px 0px rgba(255, 255, 255, 0.3) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.4) inset',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top left',
        filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25))',
      },
      '&[data-isRacing="true"]': {
        '& > div': {
          _first: {
            display: [null, null, 'flex'],
          },
          _last: {
            display: ['none', null, 'flex'],
          },
        },
      },
      '&[data-isRacing="false"]': {
        '& > div': {
          _first: {
            display: ['none', null, 'flex'],
          },
          _last: {
            display: [null, null, 'flex'],
          },
        },
      },
    },
  },
  container: {
    display: 'flex',
    flexDir: 'column',
    mx: ['auto', null, 'unset'],
  },
  racingBtnsContainer: {
    gap: 1,
    flexGrow: 1,
    justifyContent: ['center', null, 'flex-start'],
    flexDirection: 'row',
    pl: '0',
    maxHeight: '50px',
    flexWrap: ['wrap', 'nowrap', null],
    overflow: 'hidden',
    maxWidth: ['calc(98px*3 + 4px*2)', 'unset', 'unset'],
  },
  sportBtnsContainer: {
    flexGrow: 2,
    gap: 1,
    justifyContent: ['center', null, 'flex-start'],
    flexDirection: 'row',
    pl: '0',
    maxHeight: '50px',
    flexWrap: 'wrap',
    overflow: 'hidden',
    maxWidth: 'calc(98px*6 + 4px*5)',
  },
  racingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'gamma.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    boxSize: '4',
    mr: '0',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'gamma.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
    boxSize: '4',
    mr: '0',
    sx: {
      'svg[data-icon="american-football"]': {
        color: 'delta.100',
      },
      'svg[data-icon="australian-rules"]': {
        color: 'delta.200',
      },
      'svg[data-icon="baseball"]': {
        color: 'delta.300',
      },
      'svg[data-icon="basketball"]': {
        color: 'delta.400',
      },
    },
  },
  linkIconBox: {
    sx: {
      '&&': {
        gap: '1',
        h: '50px',
        minW: ['98px', null, '118px'],
        overflow: 'hidden',
        borderRadius: 'md',
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(193, 146, 98, 1) inset, 0px 0px 0px 5px rgba(27, 30, 31, 1) inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
        bgGradient:
          'linear(to-b, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.14))',
        _hover: {
          bgGradient: 'linear(to-b, alpha.400, alpha.400)',
        },
      },
    },
  },
  linkBox: {
    sx: {
      '&&': {
        gap: '1',
        h: '50px',
        minW: ['98px', null, '110px'],
        overflow: 'hidden',
        borderRadius: 'md',
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(193, 146, 98, 1) inset, 0px 0px 0px 5px rgba(27, 30, 31, 1) inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
        bgGradient:
          'linear(to-b, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.14))',
        _hover: {
          bgGradient: 'linear(to-b, alpha.400, alpha.400)',
        },
      },
    },
  },
  linkOverlay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    gap: '2',
  },
  linkBoxText: {
    color: 'gamma.300',
    fontFamily: 'SilvanoWestern',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '2xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    lineHeight: '10px',
  },
  racingHeader: {
    display: 'none',
  },
  sportsHeader: {
    display: 'none',
  },
  sportsHeaderTile: {
    color: 'gamma.300',
    fontFamily: 'SilvanoWestern',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '2xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    display: 'block',
    textAlign: 'center',
    w: ['135%', null, '105%', null, null],
    lineHeight: '10px',
  },
  linkOverlayOverride: {
    color: 'white',
    fontFamily: 'Staatliches',
    fontSize: 'xs',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    w: '100%',
  },
  sportIconOverride: {
    color: 'gamma.300',
    fontFamily: 'Staatliches',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
    w: '56px',
  },
};
