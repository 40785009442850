import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'beta.500', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'beta.500'] },
};

export const FlexHeadingRow: CSSObject = {};

export const FlexHeading: CSSObject = {
  color: 'white',
  top: ['12', '84px'],
  position: 'unset',
  mb: '1',
};

export const TextHeading: CSSObject = {
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'white',
  pt: '1',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  color: 'beta.500',
  bgGradient: 'linear(to-b, alpha.800, alpha.900)',
  border: 'none',
  borderRadius: 'md',
  p: '2',
  boxShadow: '0px 2px 2px 0px rgba(125, 253, 42, 0.35) inset',
};

export const BoxPageWrapper: CSSObject = {
  m: '0',
  mb: '4',
  p: '0.5',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
  borderRadius: 'lg',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(0, 0, 0, 0.25)',
};

export const BoxTableWrapper: CSSObject = {
  p: '0',
  bg: 'alpha.500',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'accent',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'whiteAlpha.500',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextRaceResults: CSSObject = {
  color: 'white',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'blackAlpha.400';
  })(),
});

export const LinkRaceItem = () => ({
  bg: ['transparent', null, 'alpha.200'],
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderBottomColor: 'whiteAlpha.200',
  borderRightColor: 'whiteAlpha.200',
  _hover: {
    background: 'alpha.300',
  },
  _last: {
    borderRight: 'none',
  },
});

export const FlexRow: CSSObject = {
  mb: '0',
  borderRadius: 'none',

  _last: {
    borderBottom: 'none',
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  bg: '#464746',
  py: '1.5',
  borderColor: 'unset',
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: '1px solid',
  borderRight: '1px solid',
  borderBottomColor: 'whiteAlpha.200',
  borderRightColor: 'whiteAlpha.200',
};

export const CenterTH = (): CSSObject => ({
  bg: '#404140',
  color: 'white',
  textAlign: 'center',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'extrabold',
  lineHeight: 'normal',
  py: '2.5',
  border: '1px solid',
  borderLeft: 'none',
  borderColor: 'whiteAlpha.200',
  textTransform: 'uppercase',
});

export const ButtonChevron: CSSObject = {
  color: 'white',
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  boxSize: '7',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};

export const BadgeRaceNumber: CSSObject = {
  bg: 'gray.300',
  color: 'gray.600',
  py: '1',
  borderRadius: 'base',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};
