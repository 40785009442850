import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/titanbet/images/close-filter.svg';

const primary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.300 0%, gamma.400 100%)',
  color: 'beta.800',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 4px rgba(255, 255, 255, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px #FFD69E inset, 0px 0px 0px 3px #BD873E inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, yellow.600 0%, yellow.600 100%)',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  bgGradient: 'linear(to-b, beta.400 0%, beta.600 100%)',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.500',
  color: 'beta.100',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #FFC878 inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.600 0%, beta.600 100%)',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
    color: 'gamma.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 0px 14px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.300',
    border: 'none',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    border: 'none',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.200',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  _hover: {
    bg: 'blackAlpha.500',
  },
  _active: {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 0px 14px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.300',
    border: 'none',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    border: 'none',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    tab: {
      boxShadow: 'none',
      border: 'none',
      fontSize: 'sm',
      textShadow: 'none',
      fontWeight: 'normal',
      borderRadius: 'none',
      fontFamily: 'SilvanoWestern',
      h: '8',
      textTransform: 'uppercase',
      color: 'alpha.300',
      borderBottom: '1.5px solid',
      borderBottomColor: 'transparent',
      px: '3',
      pt: '2',
      mb: '0.5',
      _hover: {
        color: 'gamma.300',
      },
      '&:active, &[data-active="true"]': {
        color: 'gamma.300',
        fontWeight: 'normal',
        border: 'none',
        borderBottom: '1.5px solid',
        borderBottomColor: 'gamma.300',
      },
    },
    filter: {
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'extrabold',
      h: '7',
      minW: 'auto',
      p: '2',
      textTransform: 'uppercase',
      border: '2px',
      borderColor: 'beta.200',
      bg: 'transparent',
      _hover: {
        color: 'beta.600',
      },
      _active: {
        bgGradient: 'linear(to-b, alpha.200, alpha.300)',
        color: 'beta.600',
        border: 'none',
        '&:after': {
          border: 'none',
          content: '""',
          position: 'absolute',
          display: 'block',
          boxSize: '14px',
          top: '6.4px',
          right: '2',
          borderRadius: 'full',
          pr: '2',
          backgroundImage: `url(${CloseButton})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',
      bg: 'transparent',
      _hover: {
        color: 'alpha.200',
      },

      _active: {
        bg: 'beta.500',
        bgGradient: 'linear(to-b, alpha.200, alpha.300)',
        color: 'beta.600',
      },
    },
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
