import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    fontSize: 'md',
    fontWeight: 'bold',
    fontFamily: 'accent',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    pb: ['0', null, '2'],
    textTransform: 'uppercase',
    mx: ['3', null, '0'],
    pt: ['3', null, '0'],
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: '0',
  },
  hStackWrapper: {
    justifyContent: 'flex-end',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white',
    fontFamily: 'accent',
  },
  scrollButtonGroup: {
    bg: 'none',
    boxShadow: 'none',
    variant: 'filter',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'sm',
    gap: '1',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'accent',
    letterSpacing: '1px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white',
  },
  headingIcon: {
    color: 'beta.500',
  },
  propositionButton: {
    variant: 'odds',
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  boxSportWrapper: {
    display: 'none',
  },
  buttonSport: {
    pr: '30px',
    sx: {
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        border: '1px',
        borderColor: 'white',
        boxSize: '2.5',
        top: '7px',
        right: '2.5',
        borderRadius: 'full',
        pr: '2',
      },
      ':hover': {
        '&:after': {
          borderColor: 'black',
        },
      },
    },
  },
  buttonAllSports: {
    _active: {
      bg: 'beta.400',
      color: 'blackAlpha.700',
      border: 'none',
      '&:after': {
        display: 'none',
      },
    },
  },
  segmentedButtonSortByTime: {
    color: 'white',
    _hover: {
      color: 'beta.400',
    },
    _active: {
      bg: 'beta.400',
      color: 'blackAlpha.700',
    },
  },
  segmentedButtonSortBySport: {
    color: 'white',
    _hover: {
      color: 'beta.400',
    },
    _active: {
      bg: 'beta.400',
      color: 'blackAlpha.700',
    },
  },
};
