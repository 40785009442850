import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'lg',
    my: 2,
    fontFamily: 'Staatliches',
    fontWeight: '400',
  },
  heading: {
    fontWeight: 'semibold',
    fontSize: 'sm',
    mt: '-2',
  },
  promotionWrapper: {
    bg: 'alpha.900',
    my: '3',
    borderRadius: 'md',
    boxShadow: '0px 0px 0px 1px #FFFFFF1F inset, 0px 4px 10px 0px #0000004D',
    sx: {
      img: {
        borderTopRadius: 'md',
        pt: '1px',
        px: '1px',
      },
      button: {
        bgGradient: 'linear(to-b, gamma.300, gamma.500)',
        color: 'alpha.700',
        span: {
          shadow: 'none',
        }
      }
    },
  },
  flexWrapper: {
    gap: '4',
    p: '3',
    justifyContent: 'space-between',
    borderTop: '3px solid',
    borderColor: 'gamma.400',
    mx: '1px',
  },
  termsAndConditions: {
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
  },
};
