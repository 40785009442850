import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'semibold',
  fontFamily: 'accent',
};
export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  fontFamily: 'accent',
};
export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  fontFamily: 'accent',
};
export const LimitLabel: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
};

export const LimitValue: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  fontFamily: 'accent',
};
export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};
export const DepositLimitGrid: CSSObject = {
  pb: '20',
};
export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};
export const DepositButton: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const CancelButton: CSSObject = {
  variant: 'secondary',
};
export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};
export const DepositLimitContainer: CSSObject = {
  bg: 'alpha.700',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.5) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  borderRadius: 'base',
  p: '4',
};
export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: '700',
};
export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bg: 'alpha.200',
    border: 'none',
  },
  '.chakra-input__left-addon': {
    bg: 'beta.400',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'accent',
  },
  '.chakra-select__wrapper': {
    svg: {
      color: '#FFCA24 !important',
    },
  },
  '#frequency-select': {
    bg: 'beta.700',
    shadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const ModalContentWrapper: CSSObject = {
  p: '0',
};
export const ModalDivider: CSSObject = {
  display: 'none',
};
export const buttonRemoveProps: CSSObject = {
  pt: '0.5',
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  color: 'alpha.700',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bg: 'gamma.200',
  },
  ':active, &[data-active=true]': {
    bg: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  fontWeight: 'normal',
  width: 'full',
};
export const buttonConfirmModalProps: ButtonProps = {
  variant: 'odds',
  fontWeight: 'normal',
};
export const inputProps: Partial<TIndex> = {
  sx: {
    fontFamily: 'accent',
    fontSize: 'sm',
    _placeholder: {
      fontWeight: 'bold',
      fontFamily: 'accent',
    },
    boxShadow: 'none',
    '&&': {
      _hover: {
        bg: 'blackAlpha.400',
        fontFamily: 'accent',
      },
      ':focus, :active': {
        bg: 'beta.900',
        m: '0',
        h: '40px',
        fontFamily: 'accent',
      },
    },
  },
};
