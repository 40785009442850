import * as betgalaxy from './MultiCard.styles.betgalaxy';
import * as betprofessor from './MultiCard.styles.betprofessor';
import * as betroyale from './MultiCard.styles.betroyale';
import * as puntcity from './MultiCard.styles.puntcity';
import * as puntgenie from './MultiCard.styles.puntgenie';
import * as questbet from './MultiCard.styles.questbet';
import * as sterlingparker from './MultiCard.styles.sterlingparker';
import * as vikingbet from './MultiCard.styles.vikingbet';
import * as volcanobet from './MultiCard.styles.volcanobet';
import * as wellbet from './MultiCard.styles.wellbet';
import * as juicybet from './MultiCard.styles.juicybet';
import * as goldenrush from './MultiCard.styles.goldenrush';
import * as forsport from './MultiCard.styles.forsport';
import * as fiestabet from './MultiCard.styles.fiestabet';
import * as junglebet from './MultiCard.styles.junglebet';
import * as oldgill from './MultiCard.styles.oldgill';
import * as sugarcastle from './MultiCard.styles.sugarcastle';
import * as buffalobet from './MultiCard.styles.buffalobet';
import * as titanbet from './MultiCard.styles.titanbet';
import * as templebet from './MultiCard.styles.templebet';
import * as slambet from './MultiCard.styles.slambet';
import * as gigabet from './MultiCard.styles.gigabet';
import * as luckystride from './MultiCard.styles.luckystride';
import * as goldenbet888 from './MultiCard.styles.goldenbet888';
import * as mega777 from './MultiCard.styles.mega777';
import * as chromabet from './MultiCard.styles.chromabet';

import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  juicybet,
  goldenrush,
  forsport,
  fiestabet,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  templebet,
  slambet,
  gigabet,
  luckystride,
  goldenbet888,
  mega777,
  chromabet,
};
