import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  px: '0',
  py: '0',
  my: '3',
  h: '9',
};

export const ButtonFilter = () => ({
  color: 'alpha.300',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'gamma.300' : 'alpha.300',
  color: selected ? 'alpha.800' : 'transparent',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
});

export const IconRace = ({ selected }: TSelected) => ({
  color: selected ? 'gamma.300' : 'alpha.300',
});

export const Divider: CSSObject = {
  bg: 'alpha.800',
  display: 'block',
  h: '7',
  my: 'auto',
  mx: '4',
};

export const TextFilterLabel: CSSObject = {
  color: 'white',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: '0',
};
