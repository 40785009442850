import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/titanbet/images/account/topNavBg.png';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['3', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  bg: ['blackAlpha.500', null, null, 'beta.600'],
  border: ['unset', null, null, '2px solid'],
  borderColor: ['unset', null, null, 'gamma.500'],
  boxShadow: [
    'unset',
    null,
    null,
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px #1B1E1F inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.07) inset',
  ],
  borderRadius: 'md',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
  mt: '4',
  bgImage: ['unset', null, null, `url(${bg})`],
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: isActive ? ['transparent', null, null, 'beta.600'] : 'transparent',
  color: isActive
    ? ['gamma.300', null, 'alpha.200']
    : ['alpha.300', null, 'gamma.300'],
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
  textTransform: 'capitalize',
  fontWeight: 'normal',
  fontSize: 'xs',
  lineHeight: 'normal',
  py: ['2', '3'],
  pl: isActive ? ['2', '3'] : ['2', '4'],
  pr: ['2', '4'],
  fontFamily: 'accent',
  borderLeft: ['unset', null, isActive && '3px solid'],
  borderLeftColor: ['unset', null, isActive && 'beta.600'],
  borderBottom: [isActive && '2px solid', null, '1px dashed'],
  borderBottomColor: [
    isActive && 'gamma.300',
    null,
    isActive ? 'beta.600' : 'blackAlpha.400',
  ],
  borderTop: [isActive && '2px solid', null, '1px dashed'],
  borderTopColor: [isActive && 'beta.500', null, 'whiteAlpha.200'],
  _first: {
    borderTopRadius: ['none', null, '8px'],
  },
  _last: {
    borderBottomRadius: '8px',
    borderBottom: 'none',
  },
  '&:hover, &:focus': {
    bg: !isActive && 'beta.600',
  },
});
