import { CSSObject } from '@chakra-ui/react';
import errorBg from '@/assets/mega777/images/backgrounds/onboarding_bg.jpg';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexPageWrapper = (): CSSObject => ({
  flexDirection: 'column',
  h: undefined,
  justifyContent: 'center',
  minH: '100dvh',
  bg: `url(${errorBg}) center/cover no-repeat`,
});

export const MaintenanceContainer = (): CSSObject => ({
  alignItems: 'center',
  color: 'white',
  contain: 'paint',
  gap: '0',
  h: undefined,
  justifyContent: 'center',
  maxW: 'container.md',
  mb: [null, null, '12'],
  minH: [null, null, '668px'],
  mt: ['0', null, '12'],
  mx: 'auto',
  pos: 'relative',
  px: ['4', null, '28'],
  py: ['3', null, '8'],
  textShadow: '0 4px 10px rgba(0, 0, 0, .2)',
  w: 'full',
  zIndex: '1',

  _before: {
    content: '""',
    h: '275px',
    left: '0',
    pos: 'absolute',
    top: '0',
    w: 'full',
    zIndex: '-1',
  },
});

export const ContentHeadingText: CSSObject = {
  w: ['278px', '468px'],
  color: 'white',
  fontFamily: 'Staatliches',
  fontSize: ['3xl', null, '4xl'],
  lineHeight: 'none',
  mb: '4',
  mt: '4',
  textTransform: 'uppercase',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  '&[data-ismaintenance="true"]': {
    w: ['278px', '500px'],
  },
};

export const ContentBodyText: CSSObject = {
  fontSize: 'sm',
  mb: '4',

  span: {
    display: 'block',
    fontSize: 'md',
    fontWeight: 'semibold',
    mb: '4',
  },
};

export const buttonRefreshProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
  h: '10',
  minW: '102px',
  lineHeight: 'normal',
  bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  color: 'alpha.700',
  _hover: {
    color: 'alpha.700',
  },
};

export const TextBoldDescription: CSSObject = {
  fontSize: 'md',
  color: 'white',
};
