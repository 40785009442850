import * as goldenrush from './Slogan.styles.goldenrush';
import * as juicybet from './Slogan.styles.juicybet';
import * as puntcity from './Slogan.styles.puntcity';
import * as sterlingparker from './Slogan.styles.sterlingparker';
import * as vikingbet from './Slogan.styles.vikingbet';
import * as forsport from './Slogan.styles.forsport';
import * as junglebet from './Slogan.styles.junglebet';
import * as oldgill from './Slogan.styles.oldgill';
import * as sugarcastle from './Slogan.styles.sugarcastle';
import * as buffalobet from './Slogan.styles.buffalobet';
import * as titanbet from './Slogan.styles.titanbet';
import * as mega777 from './Slogan.styles.mega777';
import * as gigabet from './Slogan.styles.gigabet';
import * as slambet from './Slogan.styles.slambet';
import * as luckystride from './Slogan.styles.luckystride';
import * as templebet from './Slogan.styles.templebet';
import * as goldenbet888 from './Slogan.styles.goldenbet888';
import * as chromabet from './Slogan.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  goldenrush,
  juicybet,
  puntcity,
  sterlingparker,
  vikingbet,
  forsport,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  mega777,
  gigabet,
  slambet,
  luckystride,
  templebet,
  goldenbet888,
  chromabet,
};
