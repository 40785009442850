import { CSSObject, ButtonProps } from '@chakra-ui/react';
import { TTableText } from '../../../types';

export const TableItem: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
  borderRight: 'none',
  color: 'white',
  pl: 0,
  py: 2,
};
export const TableHeadingText: CSSObject = {
  color: 'white',
};
export const TransactionWrapper: CSSObject = {
  h: 'auto',
};
export const TableText = ({ textVariation }: TTableText): CSSObject => ({
  color: textVariation === 'positive' ? 'beta.400' : 'white',
  fontSize: 'xs',
});
export const NoTransactionsText: CSSObject = {
  color: 'white',
};
export const buttonMoreTableProps: ButtonProps = {
  pt: '1.5',
  fontWeight: 'normal',
};
export const BoxTransactionsContainer: CSSObject = {
  ml: '1',
  fontFamily: 'accent',
};
