import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '1',
};
export const Subheading: CSSObject = {
  color: 'white',
  span: {
    color: 'white',
  },
};
export const DividerWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'primary',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  pt: '0.5',
  mb: '4',
};
export const ButtonWithdrawal: CSSObject = {
  variant: 'secondary',
  fontWeight: 'normal',
};
export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
};
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'white',
    fontWeight: 'medium',
    fontSize: 'sm',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
  '.chakra-form__error-message': {
    bg: 'gamma.400',
    shadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'alpha.300',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    pt: '0',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const AmountInputWrapperWithdrawal: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bg: 'beta.400',
    border: 'none',
    '#amount-input': {
      bg: '#252626',
      border: 'none',
      my: '2px',
      mr: '2px',
      fontSize: 'sm',
      fontWeight: 'bold',
      color: 'white',
      borderLeft: 'none',
      borderLeftRadius: 'none',
      _placeholder: {
        fontWeight: 'bold',
      },
      _hover: {
        bg: '#333B47',
        border: 'none',
      },
      '&&:focus, &&:active': {
        bg: '#252626',
        boxShadow: 'none',
        border: '2px solid',
        borderColor: 'beta.400',
        borderLeft: 'none',
        borderLeftRadius: 'none',
        m: '0',
        h: '40px',
      },
    },
  },
  '.chakra-input__left-addon': {
    bg: 'beta.400',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-form__error-message': {
    bg: 'gamma.400',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'alpha.300',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    pt: '0',
    svg: {
      fontSize: 'sm',
    },
  },
};
/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const BankRadio: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'beta.700',
    border: '3px solid',
    borderColor: 'white',
    _hover: {
      bg: 'blue.600',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};
export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};
export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};
/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '0',
  p: '0',
  w: '100%',
};
export const ListContainerHeading: CSSObject = {
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'lg',
  mt: '3',
  '&&': {
    fontWeight: 'normal',
  },
};
/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};
export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};
export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  borderRadius: 'base',
  bg: status === EWithdrawalStatus.Pending && 'beta.500',
  color: status === EWithdrawalStatus.Pending && 'blackAlpha.700',
  textTransform: 'uppercase',
  fontSize: '2xs',
  fontWeight: 'bold',
});
export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const IncreaseAmountWrapper: CSSObject = {
  mb: '3',
};
export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '2',
  variant: 'odds',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};
export const ButtonWithdrawalCardCancel: CSSObject = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'beta.400',
  color: 'white',
  fontFamily: 'ProximaNova',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    bg: 'whiteAlpha.100',
    border: '2px',
    borderColor: 'white',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: '0px 0px 8px rgba(37, 254, 214, 0.7)',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};
export const WithdrawalWrapper: CSSObject = {
  border: 'none',
  p: '2',
  bg: 'transparent',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
};
export const WithdrawalCardContent: CSSObject = {
  color: 'white',
};
export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
};
export const WithdrawalCardDetailRequested: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'blackAlpha.500',
};
export const InputWithdrawal: CSSObject = {
  '&&&': {
    border: 'none',
    bg: 'alpha.300',
    '&&:hover, &&:focus, &&:active': {
      bg: 'alpha.400',
    },
  },
};
