import { CSSObject } from '@chakra-ui/react';
import featureDesktop from '@/assets/mega777/images/homeFeaturedSport/featSport.jpg';
import featuredMb from '@/assets/mega777/images/homeFeaturedSport/featSportMb.jpg';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  border: 'none',
  bgImage: [`url(${featuredMb})`, `url(${featureDesktop})`],
  bgPos: 'bottom',
  bgRepeat: 'no-repeat',
  bgSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  borderRadius: 'md',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(2.5px)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'gamma.400',
  fontSize: 'md',
  fontWeight: 'normal',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'gamma.400',
  fontFamily: 'Staatliches',
  boxShadow: 'unset',
};

export const TextMisc: CSSObject = {
  borderRadius: 'md',
  color: 'beta.800',

  fontSize: 'xs',
  fontWeight: 'extrabold',

  bg: 'beta.400',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  '& span': {
    color: 'rgba(0, 0, 0, 0.36)',
  },
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'xl',
  fontWeight: 'normal',
  fontFamily: 'Staatliches',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontFamily: 'Staatliches',
  fontSize: '3xl',
  fontWeight: 'normal',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  color: 'gamma.400',
};

export const puntButtonProps: CSSObject = {
  bg: 'gamma.400',
  borderRadius: 'base',
  color: 'alpha.700',
  fontWeight: 'normal',
  h: '9',
  // Todo: remove !important
  background:
    'var(--gradients-gradient-gamma-300-500, linear-gradient(180deg, #FFD562 0%, #DEA913 100%)) !important',
  boxShadow:
    '0px 3px 5px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset !important',
};
