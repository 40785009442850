import * as betprofessor from './RaceTiles.styles.betprofessor';
import * as betroyale from './RaceTiles.styles.betroyale';
import * as fiestabet from './RaceTiles.styles.fiestabet';
import * as goldenrush from './RaceTiles.styles.goldenrush';
import * as juicybet from './RaceTiles.styles.juicybet';
import * as puntcity from './RaceTiles.styles.puntcity';
import * as puntgenie from './RaceTiles.styles.puntgenie';
import * as questbet from './RaceTiles.styles.questbet';
import * as sterlingparker from './RaceTiles.styles.sterlingparker';
import * as vikingbet from './RaceTiles.styles.vikingbet';
import * as volcanobet from './RaceTiles.styles.volcanobet';
import * as forsport from './RaceTiles.styles.forsport';
import * as junglebet from './RaceTiles.styles.junglebet';
import * as luckystride from './RaceTiles.styles.luckystride';
import * as sugarcastle from './RaceTiles.styles.sugarcastle';
import * as oldgill from './RaceTiles.styles.oldgill';
import * as buffalobet from './RaceTiles.styles.buffalobet';
import * as titanbet from './RaceTiles.styles.titanbet';
import * as templebet from './RaceTiles.styles.templebet';
import * as mega777 from './RaceTiles.styles.mega777';
import * as goldenbet888 from './RaceTiles.styles.goldenbet888';
import * as gigabet from './RaceTiles.styles.gigabet';
import * as slambet from './RaceTiles.styles.slambet';
import * as chromabet from './RaceTiles.styles.chromabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  forsport,
  junglebet,
  luckystride,
  sugarcastle,
  oldgill,
  buffalobet,
  titanbet,
  templebet,
  mega777,
  goldenbet888,
  gigabet,
  slambet,
  chromabet,
};
