import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './BreadcrumbTwo.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    pl: '4',
    pr: '2.5',
    h: '10',
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbLinkItem: {
    fontSize: '2xs',
    fontWeight: 'extrabold',
    color: 'white',
    textDecoration: 'none',

    sx: {
      '&:not([href])': {
        color: 'gamma.300',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
