/* eslint-disable no-return-assign */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Collapse,
  Flex,
  HStack,
  Portal,
  Show,
  Text,
  Icon,
} from '@chakra-ui/react';
import { ArrowUp } from '@styled-icons/fa-solid/ArrowUp';
import { FormattedMessage } from 'react-intl';
import { getStrings, formatStake } from '@/helpers/utils';
import {
  EBetSlipBetSubmissionType,
  TBetSlipBet,
  TBetSlipBetExotics,
  TBetSlipBetSingle,
  TBetSlipBonusChanceType,
  TPromoToken,
} from '../../../../Services/Betslip.types';
import FormsInput from './FormsInput';
import {
  useBetSlipSubmit,
  useBetSpecificViewMode,
  useExoticsFlexi,
  useStake,
} from '../../../../Services/Betslip.hooks';
import {
  FlexReviewExotic,
  StakeInput,
  ReviewContainer,
  ReviewStakeText,
  ReviewStake,
  ReviewEstText,
  ReviewEst,
  BoxReviewStakeContainer,
  formStyles,
} from './Forms.styles';
import { FEATURE_FLAGS } from '../../../../../../constants/featureFlags';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import {
  calculateExoticFlexi,
  getPromoToken,
} from '@/components/Betslip/Services/Betslip.utils';
import { getBetSlipStoreActions } from '@/store/BetSlipStore';
import { BetslipRefSchema } from '../../../Modal/ModalWeb';
import Keyboard from '../../../Keyboard/Keyboard';
import { validateStake } from '@/components/Betslip/lib';
import { useAuth } from '@/hooks/useAuth';
import { TPunterBetRequest } from '@/api/punter/punter.types';
import { isToteMulti } from '@/views/races/bets/Exotics/services/Exotics.utils';

// Container ---

type FormsStakeContainerProps = Pick<FormsStakeProps, 'bet' | 'betslipRef'> & {
  betRequest: TPunterBetRequest | undefined;
};

export default function FormsStakeContainer(props: FormsStakeContainerProps) {
  const { bet, betslipRef, betRequest } = props;
  const values = useFormStake({ bet, betslipRef, betRequest });
  return <FormsStakeView {...props} {...values} />;
}

// View ---

type FormsStakeProps = {
  bet: TBetSlipBet;
  betslipRef: BetslipRefSchema;
  setShowKeypad: Dispatch<SetStateAction<boolean>>;
  showKeypad: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  boostedOddsPromoToken: TPromoToken | undefined;
  onChange: (value: string) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  inputValue: () => string;

  betIsInReview: boolean | undefined;
  showOddsBoost: boolean;
};

const FormsStakeView = ({
  bet,
  betslipRef,
  showKeypad,
  setShowKeypad,
  inputValue,
  onChange,
  onFocus,
  boostedOddsPromoToken,
  inputRef,
  // -- these need to be refactored
  betIsInReview,
  showOddsBoost,
}: FormsStakeProps) => {
  const [{ Generic, BetSlip: Strings }] = getStrings();
  const hasOddsBoost = !!boostedOddsPromoToken && showOddsBoost;
  const { handleFlexiChange } = useExoticsFlexi(bet);
  const { updateBet } = getBetSlipStoreActions();

  const isFixedOdds =
    bet.price_type !== 'starting' &&
    bet.price_type !== 'tote_single_mid' &&
    bet.price_type !== 'tote_single_best';

  const isReviewable =
    !!betIsInReview &&
    ['Single', 'EachWay', 'SRMulti', 'SGMulti', 'Blended', 'Multi'].includes(
      bet.type
    );

  const hasRollover = !!(bet as TBetSlipBetSingle)?.rollovers?.length;

  return (
    <HStack flex="1">
      {isReviewable && (
        <ReviewContainer>
          <BoxReviewStakeContainer>
            <ReviewStakeText>{Generic.Stake}</ReviewStakeText>
            <ReviewStake>{formatStake(bet.stake)}</ReviewStake>
          </BoxReviewStakeContainer>

          <Box pt="2">
            <ReviewEstText>
              <FormattedMessage
                id={
                  hasRollover
                    ? 'betSlip.combinedEstPayout'
                    : 'betSlip.estPayout'
                }
              />
            </ReviewEstText>
            <Flex justifyContent="center" flexDirection="column">
              {hasOddsBoost && (
                <ReviewEst>
                  {formatStake(
                    (boostedOddsPromoToken?.boosted_odds ?? 0) *
                      Number(bet.stake)
                  )}
                </ReviewEst>
              )}

              <ReviewEst oddsHaveBeenBoosted={hasOddsBoost}>
                {isFixedOdds ? (
                  formatStake(bet.potential_returns ?? 0)
                ) : (
                  <FormattedMessage id="generic.na" />
                )}
              </ReviewEst>
            </Flex>
          </Box>
          {hasOddsBoost && (
            <Flex {...formStyles.flexBoostedOddsWrapper}>
              <Icon as={ArrowUp} sx={{ ...formStyles.iconArrowUp }} />

              <Text {...formStyles.textBoostedOdds}>
                <FormattedMessage id="account.myBetsCard.boostedOdds" />
              </Text>
            </Flex>
          )}
        </ReviewContainer>
      )}

      {betIsInReview && bet.type === EBetSlipBetSubmissionType.Exotics && (
        <FlexReviewExotic>
          <ReviewStakeText>{Generic.Stake}</ReviewStakeText>
          <ReviewStake>{Number(bet?.stake ?? 0)?.toFixed(2)}</ReviewStake>
        </FlexReviewExotic>
      )}

      {!betIsInReview && (
        <>
          <StakeInput>
            <FormsInput
              ref={inputRef}
              placeholder={Generic.EmptyStake}
              bet={bet}
              value={inputValue()}
              fieldName="stake"
              onBlur={() => {
                if (
                  bet.type === 'Exotics' &&
                  !isToteMulti(bet.subType as string)
                ) {
                  const exoticBet = bet as TBetSlipBetExotics;
                  let flexiValue = calculateExoticFlexi(
                    Number(exoticBet.stake),
                    exoticBet.validSelectionAmount
                  );
                  const isDecimal = flexiValue % 1 !== 0;

                  if (isDecimal) {
                    flexiValue = Math.floor(flexiValue);
                    const newStake = +(
                      (flexiValue * exoticBet.validSelectionAmount) /
                      100
                    ).toFixed(2);

                    handleFlexiChange(String(flexiValue));

                    if (exoticBet?.id)
                      updateBet({
                        id: exoticBet.id,
                        values: {
                          stake: String(newStake),
                          showFlexiChangeBanner: true,
                        },
                      });
                  }
                }

                // CODE FOR TOTE_MULTI
                // if (isToteMulti(bet.subType as string)) {
                //   const combos = calculateCombosForToteMulti(bet.selection);

                //   let flexiValue = Number(
                //     ((Number(bet.stake) / combos) * 100).toFixed(2)
                //   );
                //   const isDecimal = flexiValue % 1 !== 0;

                //   if (isDecimal) {
                //     flexiValue = Math.floor(flexiValue);
                //     const newStake = Number(
                //       ((Number(flexiValue) * combos) / 100).toFixed(2)
                //     );

                //     handleFlexiChange(String(flexiValue));
                //     if (bet.id)
                //       updateBet({
                //         id: bet.id,
                //         values: { stake: String(newStake) },
                //       });
                //   }
                // }
              }}
              prefix="$"
              keypadPrefix="$"
              cyLabel="betCardStakeInput"
              errorMessage={Strings.betSlipBetCard.invalidStake}
              onChange={(e) => {
                if (
                  bet.type === 'Exotics' &&
                  !isToteMulti(bet.subType as string)
                ) {
                  updateBet({
                    id: bet.id,
                    values: {
                      showFlexiChangeBanner: false,
                    },
                  });
                }
                onChange(e);
              }}
              onFocus={onFocus}
              width="100%"
            />
          </StakeInput>

          <Portal
            containerRef={(() => {
              const portal = betslipRef?.current.portalRef?.current;
              if (portal) return betslipRef?.current.portalRef;
            })()}
          >
            <KeyboardContainer
              betslipRef={betslipRef}
              setShowKeypad={setShowKeypad}
              isOpen={showKeypad}
            />
          </Portal>
        </>
      )}
    </HStack>
  );
};

// Controller ---
type UseFormStakeProps = Pick<FormsStakeProps, 'bet' | 'betslipRef'> & {
  betRequest: TPunterBetRequest | undefined;
};

const useFormStake = ({ bet, betslipRef, betRequest }: UseFormStakeProps) => {
  const newBetID = bet.id;
  const inputRef = useRef<HTMLInputElement>(null);
  const [showKeypad, setShowKeypad] = useState(false);
  const boostedOddsPromoToken = getPromoToken(
    betRequest?.promo_tokens ?? bet.confirmation?.promo_tokens,
    'odds-boost'
  );
  const { updateBet } = getBetSlipStoreActions();
  const oddsBoostRef = useRef<Partial<TBetSlipBonusChanceType>>();
  /**
   * TODO:
   * The following vars need to be refactored
   * */
  const { updateBetStake } = useStake();
  const { betIsInReview } = useBetSpecificViewMode(bet);
  const { bonusChance } = useAppSelector((state) => state.betSlip);

  /**
   * Monitors changes to the visibility of the odds boost feature.
   * Stores a reference upon visibility. This reference acts as a marker to
   * determine if the animation for the odds boost has been executed and
   * subsequently removed. In cases where the bonusChance data is missing but
   * the reference exists, it implies the animation was played. Boosted values
   * are displayed only after the completion of this animation.
   */
  useEffect(() => {
    if (
      bonusChance?.game === 'odds-boost' &&
      bonusChance?.isVisible &&
      !oddsBoostRef.current
    ) {
      oddsBoostRef.current = bonusChance;
    }
  }, [bonusChance, bonusChance?.isVisible]);

  useEffect(() => {
    const handleBetsRef = () => {
      if (betslipRef) {
        const betWrapper = betslipRef.current.betsGroupRef.current;
        const inputs =
          betWrapper?.querySelectorAll<HTMLInputElement>('[data-input]');

        const bets: HTMLInputElement[] = [];
        inputs?.forEach((inp) => bets.push(inp));

        betslipRef.current.betsRef = bets.map((b) => ({
          id: b.id,
          isFocused: false,
          ref: b,
        }));
      }
    };

    handleBetsRef();

    return () => {
      handleBetsRef();
    };
  }, [betslipRef]);

  /**
   * value function for the stake input
   */
  const inputValue = () => {
    const isNumber = !Number.isNaN(Number(bet.stake));
    return isNumber ? String(bet.stake) : '';
  };

  /**
   * onChange event for the stake input
   */
  const onChange = (value: string) => {
    const formattedValue = validateStake(value);

    if (!newBetID) {
      updateBetStake(bet, formattedValue);
    } else {
      updateBet({
        id: newBetID,
        values: { stake: formattedValue },
      });
    }
  };

  /**
   * onFocus event for the stake input
   */
  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (betslipRef) {
      const updatedBets = betslipRef?.current.betsRef?.map((b) => ({
        ...b,
        isFocused: e?.target?.id === b.id,
      }));
      betslipRef.current.betsRef = updatedBets;

      setShowKeypad(true);
    }
  };

  return {
    boostedOddsPromoToken,
    newBetID,
    inputRef,
    showKeypad,
    setShowKeypad,
    inputValue,
    onChange,
    onFocus,
    betIsInReview,
    showBonusChance: bonusChance?.isVisible === true,
    bonusChance,
    showOddsBoost:
      !bonusChance?.game && oddsBoostRef.current?.isVisible === true,
  };
};

// --------- Keypad

// Container ---

type KeyboardContainerProps = Pick<
  FormsStakeProps,
  'betslipRef' | 'setShowKeypad'
> & { isOpen: boolean };

function KeyboardContainer({
  betslipRef,
  setShowKeypad,
  isOpen,
}: KeyboardContainerProps) {
  const props = useKeyboard({ betslipRef, setShowKeypad });
  return (
    <Show below="md">
      <Collapse in={isOpen} animateOpacity>
        <Keyboard {...props} />
      </Collapse>
    </Show>
  );
}

// Controller ---

type UseKeyboardProps = Pick<
  KeyboardContainerProps,
  'betslipRef' | 'setShowKeypad'
>;

const useKeyboard = ({ betslipRef, setShowKeypad }: UseKeyboardProps) => {
  const { updateBet } = getBetSlipStoreActions();
  const { isAuthenticated } = useAuth();
  const { handleSubmit } = useBetSlipSubmit();
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      try {
        updateBet((store) => {
          const _bets = { ...(store ?? {}) };
          const keys = Object.keys(_bets);
          keys.forEach((k) => {
            if (_bets?.[k]?.stake) {
              _bets[k].stake = validateStake(_bets[k].stake ?? '')?.replace(
                /\.$/,
                ''
              );
            }
          });
          return _bets;
        });

        await handleSubmit();
        setShowKeypad(false);
      } catch (error) {
        console.log('keypad onSubmit error', error);
      }
    }
  };

  const onClick = (key: string) => {
    const nonStakeActions = ['submit', 'next', 'prev', 'hide'];

    if (betslipRef) {
      if (nonStakeActions.includes(key)) {
        const focusedIndex = betslipRef.current.betsRef.findIndex(
          (b) => b.isFocused
        );

        const handlers: { [key: string]: () => void } = {
          submit: () => onSubmit(),
          hide: () => setShowKeypad(false),
          next: () => {
            if (focusedIndex > -1) {
              betslipRef.current.betsRef[focusedIndex + 1]?.ref.focus();
            } else {
              betslipRef.current.betsRef[0]?.ref.focus();
            }
          },
          prev: () => {
            if (focusedIndex > -1) {
              betslipRef.current.betsRef[focusedIndex - 1]?.ref.focus();
            } else {
              betslipRef.current.betsRef[0]?.ref.focus();
            }
          },
        };
        const handler = handlers[key];

        if (handler) handler();
      } else {
        if (FEATURE_FLAGS.HAS_NEW_BS) {
          updateBet((store) => {
            const _bets = { ...(store ?? {}) };
            const idx = betslipRef.current.betsRef.findIndex(
              (b) => b.isFocused
            );

            if (idx > -1) {
              const refBet = betslipRef.current.betsRef[idx].id;
              const _bet = _bets[refBet ?? ''];

              if (_bet) {
                if (key === '-') {
                  _bet.stake = _bet.stake
                    ? String(_bet.stake).slice(0, -1)
                    : '';
                } else if (!Number.isNaN(Number(key)) && Number(key) > 9) {
                  const isNumber = !Number.isNaN(Number(_bet.stake));
                  _bet.stake = String(
                    Number(isNumber ? _bet.stake : 0) + Number(key)
                  );
                } else {
                  _bet.stake = _bet.stake
                    ? String(_bet.stake) + String(key)
                    : key;
                }

                _bet.stake = validateStake(_bet.stake ?? '');
              }
            }
            return _bets;
          });
        } else {
          try {
            /**
             * This is hacky but its only used when the FF is off
             * which should be removed shortly.
             */
            const idx = betslipRef.current.betsRef.findIndex(
              (b) => b.isFocused
            );
            if (idx > -1) {
              const refBet = betslipRef.current.betsRef[idx].ref;
              /* eslint-disable */
              const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement?.prototype,
                'value'
              )?.set;
              /* eslint-enable */

              let { value } = refBet;

              if (key === '-') {
                value = value ? String(value).slice(0, -1) : '';
              } else if (!Number.isNaN(Number(key)) && Number(key) > 9) {
                const isNumber = !Number.isNaN(Number(value));
                value = String(Number(isNumber ? value : 0) + Number(key));
              } else {
                value = value ? String(value) + String(key) : key;
              }

              nativeInputValueSetter?.call(refBet, value);
              const inputEvent = new Event('input', { bubbles: true });
              refBet.dispatchEvent(inputEvent);
            }
          } catch (error) {
            console.log('keypad error', error);
          }
        }
      }
    }
  };

  return { onClick };
};
