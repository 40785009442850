import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: 'alpha.100',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  p: '3',
  color: 'gamma.200',
};

export const HeaderText: CSSObject = {
  color: 'beta.400',
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  p: '0',
};

export const HeaderContainer: CSSObject = {
  p: '0',
};

export const DepositLimitModalSubheading: CSSObject = {
  '&&': {
    color: 'gamma.200',
  },
};

export const CloseButton: CSSObject = {
  display: 'none',
};
