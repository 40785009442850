import React from 'react';
import { Box } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import Toggle from '@/views/Home/conditionals/Toggle/template.mega777';

export const template: TTemplate = {
  wrapper: (
    <Box>
      <Toggle />
    </Box>
  ),
};

export default template;
