import { CSSObject } from '@chakra-ui/react';

import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  variant: 'primary',
  w: '12',
  h: '8',
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  width: 'calc(100% - 4px)',
  maxH: '3',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '2px',
  borderTopLeftRadius: 'base',
  borderTopRightRadius: 'base',
  bg: 'beta.900',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px -1px 0px 0px rgba(0, 0, 0, 0.05) inset',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'white',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontFamily: 'roboto',
  fontWeight: 'bold',
  textShadow: '0px 2px 0px rgba(0, 0, 0, 0.15)',
};
