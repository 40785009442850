import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      h: '38px',
      background: 'alpha.300',
      color: 'white',
      borderRadius: 'lg',
      transition: 'all .2s ease-in-out',
      fontSize: 'xs',
      px: '2',
      'input, span': {
        fontFamily: 'accent',
        fontWeight: 'normal',
      },
      button: {
        color: 'beta.300',
      },
      boxShadow: 'none',
      _hover: {
        opacity: 0.9,
      },
    },
  },
};
