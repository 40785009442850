import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  mb: '0',
  px: '2',
  pb: '1',
  bg: '#7244EE',
  borderRadius: 'md',
  flex: '1',
};
export const RaceResultsHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
};
export const RunnerResultRow: CSSObject = {
  borderTop: '1px dashed rgba(255, 255, 255, 0.15)',
  borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',
  py: '1.5',
  h: '12',
  _last: {
    borderBottom: 'none',
  },
};
export const RunnerDetails: CSSObject = {
  gap: '2',
};
export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  color: 'alpha.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  textTransform: 'uppercase',
  padding: '2',
  fontSize: '2xs',
  '&&': {
    h: '7',
    w: '9',
  },
  border: 'none',
};
export const RunnerNumber: CSSObject = {
  fontWeight: 'normal',
  fontSize: '2xs',
  color: 'gamma.200',
  pos: 'relative',
  bottom: '2px',
};
export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};
export const RunnerOdds: CSSObject = {
  w: '12',
  h: '9',
  bg: 'blackAlpha.400',
  border: 'none',
  borderRadius: 'md',
  color: 'rgba(255, 255, 191, 0.6)',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  fontWeight: 'normal',
  fontSize: 'sm',
};
export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  pt: '2',
  pb: '1.5',
  alignItems: 'center',
  borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',
  '.chakra-switch__track': {
    background: 'alpha.700',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
    '& .chakra-switch__thumb': {
      background: 'white',
      '&[data-checked]': {
        bg: 'alpha.200',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
      },
    },
  },
  svg: {
    color: 'white',
  },
};
export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
  fontSize: 'xs',
  fontWeight: 'medium',
};
export const FlexEventRule: CSSObject = {
  color: 'gamma.200',
};
