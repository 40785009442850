/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import { ArrowDownCircle } from '@styled-icons/bootstrap/ArrowDownCircle';
import { ArrowDownCircleFill } from '@styled-icons/bootstrap/ArrowDownCircleFill';
import { LogIn } from '@styled-icons/boxicons-regular/LogIn';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getThemeName } from '@/helpers/getThemeConfig';
import { useQueryPunterAccount } from '@/api/punter/punter.hooks';
import { EThemesOG } from '@/constants/themeConfig';
import { TExtendedProposition } from '@/views/sports/MatchDetailPage/services/MatchDetailPage.types';
import { TEvenShotResponse } from '@/api/racing/evenShot/evenShot.types';
import { usePunterData } from '@/store/AuthStore';
import { trackEvent } from '@/lib/analytics';
import { centsToDollars, getStrings } from '@/helpers/utils';
import {
  EBetSlipBetSubmissionType,
  EBetSlipViewMode,
  EBetSubmissionConfirmationStatus,
  EBetSubmissionRejectionReason,
  EEventRule,
  TBetSlipBet,
  TBetSlipBetExotics,
  TBetSlipBetMulti,
  TBetSlipBetMystery,
  TBetSlipBetSGMulti,
  TBetSlipBetSingle,
  TBetSlipBetSRMulti,
  TBetSlipBetSubmissionExoticsType,
  TBlenededBetMulti,
} from './Betslip.types';
import {
  EEventType,
  TMarket,
  TMatch,
  TPriceType,
  TProposition,
  TRace,
  TRaceMeta,
  TRunner,
  TVenue,
} from '../../../lib/DBModels';
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import {
  addBet,
  clearActionedBets,
  clearClosedEventBets,
  removeAllBets,
  removeBet,
  setBetFocused,
  setBetSlipOpen,
  setBetSlipShowFieldErrors,
  setBetSlipViewMode,
  setBonusChanceModal,
  setDisplayEventsClosedBanner,
  setDisplayOddsChangeBanner,
  setHasKeypad,
  setHasSufficientFunds,
  setUpdateConfirmFlag,
  updateBet,
} from '../../../redux/Betslip.slices';
import {
  calculateExoticFlexi,
  findConfirmationStatus,
  generateNewBetSlipBet,
} from './Betslip.utils';
import { submitBetSlip } from './Betslip.actions';
import { keys } from '../../../api/api.keys';
import {
  queryPunterAccountOverview,
  queryPunterBetRequests,
} from '../../../api/punter/punter';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { useAuth } from '../../../hooks/useAuth';
import {
  EBetRequestStatus,
  TPunterBetRequest,
} from '../../../api/punter/punter.types';
import {
  calculateCombosForToteMulti,
  isBoxed,
  isExotic,
  isToteMulti,
  roundDownStake,
} from '../../../views/races/bets/Exotics/services/Exotics.utils';
import { checkMultiValidity } from '../components/MultiCard/Services/MultiBet.utils';
import { postMobileAppMessage } from '../../../mobileapp/mobileapp.utils';
import { useQuerySupportedBets } from '../../../api/punter/supportedBets/supportedBets.hooks';
import {
  getBetSlipStoreActions,
  transformBetForLegacy,
  updateNonActionedBets,
  useBetSlipBets,
  useMoMBet,
} from '@/store/BetSlipStore';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useFeatureFlag } from '@/store/FeatureFlagStore';
import {
  TMysteryBet,
  TMysteryBetData,
} from '@/api/racing/mysteryBet/mysteryBet.types';
import { validateStake } from '../lib';

/**
 * NA
 */
export const usePunterBalance = () => {
  const { isAuthenticated } = useAuth();

  const { data, refetch } = useQuery(
    [keys.punterAccountOverview, isAuthenticated],
    queryPunterAccountOverview,
    {
      staleTime: 60 * 5000, // 5 minutes
      enabled: !!isAuthenticated,
    }
  );

  const accountBalance = data?.account_balance ?? 0;
  const bonusBalance = data?.bonus ?? 0;

  return { accountBalance, bonusBalance, refetch };
};

/**
 * NA
 */
type UseBetsCountProps = {
  bets: TBetSlipBet[];
};

export const useBetsCount = ({ bets }: UseBetsCountProps) => {
  const moMBet = useMoMBet();
  const { viewMode } = useAppSelector((state) => state.betSlip);
  // Exclude invalid multi from the betslip count
  const validBetsCount =
    bets?.filter(
      (bet) =>
        !(
          bet.type === EBetSlipBetSubmissionType.Multi &&
          !checkMultiValidity(bet as TBetSlipBetMulti)
        )
    ).length + (moMBet ? 1 : 0);

  let stakedBets = bets?.filter((bet) => Number(bet.stake) > 0).length;
  const moMBetHasStake = moMBet !== null && moMBet?.stake !== '';

  if (moMBetHasStake) {
    stakedBets += 1;
  }

  const betsCount =
    viewMode === EBetSlipViewMode.EditingBets ? validBetsCount : stakedBets;
  const betsWithStake = bets?.filter((bet) => Number(bet.stake) > 0);

  let allBetsPlaced =
    betsWithStake.filter(
      (bet) =>
        bet?.confirmation?.status === EBetSubmissionConfirmationStatus.Placed ||
        bet?.confirmation?.status ===
          EBetSubmissionConfirmationStatus.ReducedStake
    ).length === betsWithStake.length &&
    (betsWithStake.length > 0 ||
      moMBet?.betSubmissionStatus === EBetSubmissionConfirmationStatus.Placed ||
      moMBet?.betSubmissionStatus ===
        EBetSubmissionConfirmationStatus.ReducedStake);

  const momBetPlaced =
    moMBet?.betSubmissionStatus === EBetSubmissionConfirmationStatus.Placed ||
    moMBet?.betSubmissionStatus ===
      EBetSubmissionConfirmationStatus.ReducedStake;
  if (momBetPlaced) {
    allBetsPlaced = allBetsPlaced || momBetPlaced;
  }

  let someBetsPlaced =
    betsWithStake.filter(
      (bet) =>
        bet?.confirmation?.status === EBetSubmissionConfirmationStatus.Placed ||
        bet?.confirmation?.status ===
          EBetSubmissionConfirmationStatus.ReducedStake
    ).length > 0;

  if (momBetPlaced) {
    someBetsPlaced = someBetsPlaced || momBetPlaced;
  }

  let hasOddsChanges = betsWithStake.some(
    (bet) =>
      bet?.confirmation?.rejection_reason ===
      EBetSubmissionRejectionReason.OddsChange
  );

  if (moMBet) {
    hasOddsChanges = hasOddsChanges || moMBet?.betOddsChange !== null;
  }

  const betsToUpdateAndConfirmCount =
    betsWithStake.filter((bet) => {
      if (bet.type === EBetSlipBetSubmissionType.Multi) {
        const multiBet = bet as TBetSlipBetMulti;
        // If a multi bet has some props with closed events,
        // they can update and confirm straight away but only
        // if there are enough legs to place a multi.
        // Hence the -2 subtraction
        return (
          multiBet?.confirmation?.rejection_reason ===
            EBetSubmissionRejectionReason.PropositionClosed &&
          multiBet.legs.length - 2 >=
            (multiBet.confirmation.closed_propositions?.length ?? 0)
        );
      }
      return (
        bet?.confirmation?.rejection_reason ===
        EBetSubmissionRejectionReason.OddsChange
      );
    }).length +
    (moMBet?.betRejectionReason === EBetSubmissionRejectionReason.OddsChange ||
    moMBet?.betRejectionReason ===
      EBetSubmissionRejectionReason.PropositionClosed
      ? 1
      : 0);

  let hasEditableBets =
    bets?.filter(
      (bet) =>
        !bet.confirmation ||
        (bet.confirmation &&
          (bet.confirmation.rejection_reason ===
            EBetSubmissionRejectionReason.OddsChange ||
            bet.confirmation.rejection_reason ===
              EBetSubmissionRejectionReason.InsufficentFund))
    ).length > 0 || betsToUpdateAndConfirmCount > 0;

  if (moMBet) {
    hasEditableBets =
      hasEditableBets ||
      moMBet?.betOddsChange !== null ||
      moMBet?.betRejectionReason ===
        EBetSubmissionRejectionReason.InsufficentFund;
  }

  let hasClearableBets =
    bets?.filter(
      (bet) =>
        bet.confirmation?.status !== EBetSubmissionConfirmationStatus.Pending
    ).length > 0;

  if (moMBet) {
    hasClearableBets =
      hasClearableBets ||
      moMBet?.betSubmissionStatus !== EBetSubmissionConfirmationStatus.Pending;
  }

  // In the event of a rejected multi having less than two legs left to bet on,
  // the betslip should only be clearable
  const lessThanTwoEventsLeftOpen =
    (
      bets?.find(
        (bet) =>
          bet.type === EBetSlipBetSubmissionType.Multi &&
          bet.confirmation?.rejection_reason ===
            EBetSubmissionRejectionReason.PropositionClosed
      ) as TBetSlipBetMulti
    )?.legs?.filter((leg) => !leg.eventClosed)?.length <= 1;

  return {
    betsCount,
    allBetsPlaced,
    someBetsPlaced,
    hasOddsChanges,
    validBetsCount,
    hasEditableBets,
    hasClearableBets,
    betsToUpdateAndConfirmCount,
    lessThanTwoEventsLeftOpen,
  };
};

/**
 * DONE
 *
 * Hook that submits the current selected bets
 */
export const useBetSlipSubmit = () => {
  // Temp keeping both
  const betsLegacy = useAppSelector((state) => state.betSlip.bets);
  const betsNew = useBetSlipBets() ?? {};
  const moMBet = useMoMBet();

  const _keys = Object.keys(betsNew);
  const betsTransformed = _keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  const bets = FEATURE_FLAGS.HAS_NEW_BS ? betsTransformed : betsLegacy;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAuth();
  const { hasEditableBets, hasClearableBets, lessThanTwoEventsLeftOpen } =
    useBetsCount({ bets });
  const hasLD = useFeatureFlag('LUCKY_DIP_ENABLED');
  const viewMode = useAppSelector((state) => state.betSlip.viewMode);

  const { updateBet: _updateBet, updateMoMBet } = getBetSlipStoreActions();

  const handleSubmit = async () => {
    dispatch(setBonusChanceModal(null));
    dispatch(setDisplayEventsClosedBanner(false));
    dispatch(
      setBetFocused({
        field_name: '',
        request_id: '',
      })
    );
    // If we are not signed in - redirect to sign in first
    if (!isAuthenticated) {
      navigate('/login');
      dispatch(setBetSlipOpen(false));
      return;
    }

    if (lessThanTwoEventsLeftOpen) {
      dispatch(removeAllBets());
      dispatch(setBetSlipOpen(false));
      return;
    }

    if (!hasEditableBets && hasClearableBets) {
      _updateBet((store) => updateNonActionedBets(store));

      dispatch(clearActionedBets());
      dispatch(setBetSlipOpen(false));

      updateMoMBet(null);

      if (IS_MOBILE_APP) postMobileAppMessage('close_betslip', {});
      dispatch(setBetSlipViewMode(EBetSlipViewMode.EditingBets));

      return;
    }

    // If some events have closed, we allow punters to update and
    // reconfirm their selections and remove the closed bets
    dispatch(clearClosedEventBets());

    // We are editing - proceed to confirm
    if (viewMode === EBetSlipViewMode.EditingBets) {
      // Check for errors
      const betsInError = bets?.filter((bet) => bet?.errors?.length > 0);

      // filter out bets with showFlexiChangeBanner
      const betsWithFlexiChangeBanner = bets?.filter(
        (bet) => bet?.showFlexiChangeBanner
      );

      betsWithFlexiChangeBanner.forEach((bet) => {
        if (bet?.id)
          _updateBet({
            id: bet.id,
            values: {
              showFlexiChangeBanner: false,
            },
          });
      });

      // If there are any errors - show these to the user
      if (betsInError.length) {
        dispatch(
          setBetSlipShowFieldErrors(betsInError.map((bet) => bet.request_id))
        );
        return;
      }
      // No errors - move to confirmation
      dispatch(setBetSlipViewMode(EBetSlipViewMode.ReviewingBets));
      if (moMBet?.stake !== '')
        updateMoMBet({
          betSlipStatus: EBetSlipViewMode.ReviewingBets,
        });
      return;
    }

    // User has reviewed - submit
    await dispatch(submitBetSlip({ bets, queryClient, hasLD, moMBet }));
    dispatch(setUpdateConfirmFlag(true));

    if (IS_MOBILE_APP) postMobileAppMessage('placed_bet', {});
  };

  return {
    handleSubmit,
  };
};

/**
 * NA
 */
export const useBetSlipManageBets = () => {
  // Temp keeping both
  const betsLegacy = useAppSelector((state) => state.betSlip.bets);

  const betsNew = useBetSlipBets() ?? {};
  const _keys = Object.keys(betsNew);
  const betsTransformed = _keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  const bets = FEATURE_FLAGS.HAS_NEW_BS ? betsTransformed : betsLegacy;

  const dispatch = useAppDispatch();

  const singlePropositionInBetSlip = useCallback(
    (
      propositionId: string | undefined,
      priceType?: TPriceType
    ): TBetSlipBet | undefined => {
      if (!propositionId) return undefined;

      return bets?.find(
        (bet) =>
          bet.type === EBetSlipBetSubmissionType.Single &&
          (bet as TBetSlipBetSingle).proposition_id === propositionId &&
          (!priceType || (bet as TBetSlipBetSingle).price_type === priceType)
      );
    },
    [bets]
  );

  const mysteryBetInBetslip = useCallback(
    (priceType: TPriceType, raceId: string): boolean | undefined => {
      if (!raceId) return undefined;

      return bets?.some(
        (bet) => bet.price_type === priceType && bet?.race_id === raceId
      );
    },
    [bets]
  );

  const removeFromBetSlip = useCallback(
    (id: string) => dispatch(removeBet(id)),
    [dispatch]
  );

  const removeSinglePropositionFromBetSlip = (
    type: EBetSlipBetSubmissionType,
    propositionId: string | undefined,
    priceType?: TPriceType
  ) => {
    const betSlipItem = singlePropositionInBetSlip(propositionId, priceType);

    if (betSlipItem) {
      removeFromBetSlip(betSlipItem.request_id);
    }
  };

  return {
    singlePropositionInBetSlip,
    mysteryBetInBetslip,
    removeFromBetSlip,
    removeSinglePropositionFromBetSlip,
  };
};

/**
 * NA
 */
export const useSupportedBets = () => {
  const { data: supportedBets } = useQuerySupportedBets();
  const getDisplayBetType = (betType: EBetSlipBetSubmissionType) => {
    if (supportedBets && betType === EBetSlipBetSubmissionType.Exotics)
      return supportedBets?.exotics;
    if (supportedBets && betType === EBetSlipBetSubmissionType.Multi)
      return supportedBets?.multi;
    return true;
  };

  return {
    supportedBets,
    getDisplayBetType,
  };
};

/**
 * NA
 */
export const useRemoveRestrictedBets = () => {
  // Temp keeping both
  const betsLegacy = useAppSelector((state) => state.betSlip.bets);

  const betsNew = useBetSlipBets() ?? {};
  const _keys = Object.keys(betsNew);
  const betsTransformed = _keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  const bets = FEATURE_FLAGS.HAS_NEW_BS ? betsTransformed : betsLegacy;

  const { isAuthenticated } = useAuth();
  const [areUnsupportedBetsRemoved, setAreUnsupportedBetsRemoved] =
    useState(false);

  const { data: supportedBets } = useQuerySupportedBets();

  const dispatch = useAppDispatch();
  useEffect(() => {
    const removeRelevantBets = (betType: string) => {
      let betRequestIdList: string[] = [];
      switch (betType) {
        case 'racing':
          betRequestIdList = bets
            .filter((bet) => bet.event_type === EEventType.Race)
            .map((b) => b.request_id);
          break;
        case 'sport':
          betRequestIdList = bets
            .filter((bet) => bet.event_type === EEventType.Match)
            .map((b) => b.request_id);
          break;
        case 'multi':
          betRequestIdList = bets
            .filter((bet) => bet.type === EBetSlipBetSubmissionType.Multi)
            .map((b) => b.request_id);
          break;
        case 'exotics':
          betRequestIdList = bets
            .filter((bet) => bet.type === EBetSlipBetSubmissionType.Exotics)
            .map((b) => b.request_id);
          break;
        case 'starting_price':
          betRequestIdList = bets
            .filter((bet) => bet.price_type === 'starting')
            .map((b) => b.request_id);
          break;
        default:
      }
      betRequestIdList?.map((id) => dispatch(removeBet(id)));
    };

    // when punter logs in and supported bets response is populated,
    // remove any restricted bets from the betslip
    if (supportedBets && isAuthenticated && !areUnsupportedBetsRemoved) {
      Object.entries(supportedBets).map((ent) =>
        ent[1] === false ? removeRelevantBets(ent[0]) : null
      );
      setAreUnsupportedBetsRemoved(true);
    }
  }, [
    bets,
    dispatch,
    isAuthenticated,
    supportedBets,
    areUnsupportedBetsRemoved,
  ]);
};

/**
 * DONE
 * @deprecated setBet replaces this
 */
export const useSingleBetSlip = () => {
  const dispatch = useAppDispatch();
  const punterProfile = usePunterData();
  const [{ Generic }] = getStrings();
  const { supportedBets } = useSupportedBets();
  const intl = useIntl();
  const { racing, sport, multi, starting_price: sp } = supportedBets ?? {};

  // Handles adding a sports proposition to a bet slip
  const addSportsBetSlip = useCallback(
    (marketName: string, proposition: TProposition, match?: TMatch) => {
      if (
        !proposition?.proposition_id ||
        !proposition.proposition_name ||
        !proposition?.return_amount
      ) {
        toast.error(Generic.ErrorOccurred);
      }

      if (sport === false) {
        toast.error(intl.formatMessage({ id: 'generic.betTypeNotSupported' }));
        return;
      }

      const singleBet: TBetSlipBetSingle = {
        ...generateNewBetSlipBet(EBetSlipBetSubmissionType.Single),
        proposition_id: proposition?.proposition_id ?? '',
        event_type: EEventType.Match ?? '',
        odds: proposition?.return_amount ?? 0,
        event_icon: match?.sport_name ?? '',
        event_market_name: marketName,
        event_start_time: match?.match_start_time ?? '',
        event_title: proposition?.proposition_name ?? '',
        event_subtitle: match?.match_name ?? '',
        event_id: match?.match_id,
        event_data: {
          competition_id: match?.competition_id ?? '',
          match_name: match?.match_name ?? '',
          competition_name: match?.competition_name ?? '',
          sport_id: match?.sport_id ?? '',
        },
      };

      dispatch(addBet({ bet: singleBet, multisSupported: multi }));
      dispatch(clearActionedBets());

      trackEvent('addToBetSlip', {
        punterId: punterProfile?.punter_id,
      });

      if (IS_MOBILE_APP) return; // We do not want to navigate away if in the mobile app. The webview is closed

      dispatch(setBetSlipOpen(true));
    },
    [
      Generic.ErrorOccurred,
      dispatch,
      intl,
      multi,
      punterProfile?.punter_id,
      sport,
    ]
  );

  // Handles adding a win or place race runner proposition to the bet slip
  const addRaceRunnerBetSlip = useCallback(
    (
      type: 'win' | 'place',
      runner?: TRunner,
      venue?: TVenue,
      race?: TRace,
      eventRule?: EEventRule,
      priceType?: TPriceType
    ) => {
      if (racing === false || (priceType === 'starting' && sp === false)) {
        toast.error(intl.formatMessage({ id: 'generic.betTypeNotSupported' }));
        return;
      }
      const propositionId =
        type === 'win'
          ? runner?.win_proposition?.proposition_id
          : runner?.place_proposition?.proposition_id;

      let propositionOdds: number;

      if (priceType === 'tote_single_mid' || priceType === 'tote_single_best') {
        propositionOdds = 0;
      } else if (type === 'win') {
        propositionOdds = runner?.win_odds ?? 0;
      } else {
        propositionOdds = runner?.place_odds ?? 0;
      }

      const returnAmount =
        type === 'win'
          ? runner?.win_proposition?.return_amount
          : runner?.place_proposition?.return_amount;

      if (!runner || !venue || !race || !propositionId) {
        toast.error(Generic.ErrorOccurred);
        return;
      }

      const getMarketName = () => {
        if (priceType === 'starting') {
          return type === 'win'
            ? intl.formatMessage({ id: 'generic.winSP' })
            : intl.formatMessage({ id: 'generic.placeSP' });
        }

        if (
          priceType === 'tote_single_mid' ||
          priceType === 'tote_single_best'
        ) {
          return type === 'win'
            ? intl.formatMessage({ id: 'generic.winTote' })
            : intl.formatMessage({ id: 'generic.placeTote' });
        }

        return type === 'win'
          ? intl.formatMessage({ id: 'generic.fixedWin' })
          : intl.formatMessage({ id: 'generic.fixedPlace' });
      };

      const singleBet: TBetSlipBetSingle = {
        ...generateNewBetSlipBet(EBetSlipBetSubmissionType.Single),
        proposition_id: propositionId,
        odds: propositionOdds,
        potential_returns: returnAmount,
        event_icon: race?.race_type ?? '',
        event_type: EEventType.Race ?? '',
        event_market_name: getMarketName(),
        event_start_time: race?.start_time ?? '',
        event_title: `${runner?.number ?? ''}. ${runner?.display_name ?? ''}`,
        event_subtitle: `${venue?.venue_display_name ?? ''} R${
          race?.race_number ?? ''
        }`,
        race_type: race?.race_type ?? '',
        event_data: {
          race_meeting_date: race?.meeting_date,
          race_number: race?.race_number,
          venue_name: venue?.venue_name || venue?.venue_display_name,
          venue_id: venue?.venue_id,
          barrier_number: runner.barrier_number,
        },
        meeting_date: race?.meeting_date ?? '',
        runner_id: runner.race_runner_id ?? '',
        event_id: race?.race_id,
        eventRule,
        price_type: priceType,
      };

      dispatch(addBet({ bet: singleBet, multisSupported: multi }));
      dispatch(clearActionedBets());

      if (!IS_MOBILE_APP) {
        trackEvent('addToBetSlip', {
          punterId: punterProfile?.punter_id,
        });

        dispatch(setBetSlipOpen(true));
      }
    },
    [
      racing,
      sp,
      dispatch,
      multi,
      intl,
      Generic.ErrorOccurred,
      punterProfile?.punter_id,
    ]
  );

  return { addSportsBetSlip, addRaceRunnerBetSlip };
};

/**
 * DONE
 * @deprecated setBet replaces this
 */
export const useExoticsBetSlip = () => {
  const dispatch = useAppDispatch();
  const punterProfile = usePunterData();
  const { supportedBets } = useSupportedBets();
  const intl = useIntl();

  const [
    {
      Generic,
      BetSlip: { betSlipModal: BetslipStrings },
    },
  ] = getStrings();

  const addToBetSlip = (
    race: TRaceMeta,
    market: TMarket,
    venue: TVenue,
    exoticType: TBetSlipBetSubmissionExoticsType,
    placedSelections: number[][],
    validSelectionAmount: number,
    stake: number
  ) => {
    if (
      !race?.race_id ||
      !market?.propositions ||
      !market?.propositions[0] ||
      !race?.start_time ||
      !market?.market_name
    ) {
      toast.error(Generic.ErrorOccurred);
      return;
    }
    if (supportedBets && supportedBets.exotics === false) {
      toast.error(intl.formatMessage({ id: 'generic.betTypeNotSupported' }));
      return;
    }

    const proposition = market?.propositions[0];

    if (
      !proposition ||
      !proposition?.proposition_id ||
      !proposition?.proposition_name
    ) {
      toast.error(Generic.ErrorOccurred);
      return;
    }

    // Quinella's are always boxed, so prefixing in this case is unnecessary
    const eventTitle =
      isBoxed(placedSelections) &&
      exoticType !== TBetSlipBetSubmissionExoticsType.Quinella
        ? `${BetslipStrings.boxed} ${BetslipStrings.exoticTypes[exoticType]}`
        : `${BetslipStrings.exoticTypes[exoticType]}`;

    const exoticBet: TBetSlipBetExotics = {
      ...generateNewBetSlipBet(EBetSlipBetSubmissionType.Exotics, stake),
      proposition_id: proposition?.proposition_id,
      selection: placedSelections,
      sub_type: exoticType,
      event_icon: race.race_type,
      event_type: EEventType.Race,
      event_market_name: '',
      event_start_time: race?.start_time ?? '',
      event_title: eventTitle,
      event_subtitle: `${venue?.venue_display_name ?? ''} R${
        race?.race_number ?? ''
      }`,
      event_data: {
        race_meeting_date: race?.meeting_date,
        race_number: race?.race_number,
        venue_name: venue?.venue_name,
        venue_id: venue?.venue_id,
      },
      event_id: race?.race_id,
      validSelectionAmount,
    };

    dispatch(addBet({ bet: exoticBet }));

    if (!IS_MOBILE_APP) {
      trackEvent('addToBetSlip', {
        punterId: punterProfile?.punter_id,
      });

      dispatch(setBetSlipOpen(true));
    }
  };

  return { addToBetSlip };
};

/**
 * DONE
 * This handle setting & validating the stake for a bet.
 * @deprecated update bet can handle all of this
 */
export const useStake = () => {
  // Temp keeping both
  const betsLegacy = useAppSelector((state) => state.betSlip.bets);

  const betsNew = useBetSlipBets() ?? {};
  const _keys = Object.keys(betsNew);
  const betsTransformed = _keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  const bets = FEATURE_FLAGS.HAS_NEW_BS ? betsTransformed : betsLegacy;

  const dispatch = useAppDispatch();

  const updateBetStake = (bet: TBetSlipBet, value: string) => {
    if (bet) {
      dispatch(updateBet({ ...bet, stake: value }));
    }
  };

  const roundStake = (bet: TBetSlipBet) => {
    if (bet?.stake && isExotic(bet)) {
      const roundedStake = roundDownStake(bet.stake, bet.validSelectionAmount);
      updateBetStake(
        bet,
        roundedStake.toString(),
        roundedStake !== Number(bet.stake)
      );
    }
  };

  const roundAllStakes = () => bets?.map((bet) => roundStake(bet));

  return { updateBetStake, roundStake, roundAllStakes };
};

/**
 * NA
 * TODO: This hook can be refactored and removed.
 * Keeping it temporarily to reduce PR scope.
 *
 * This handles calculating and update the stake/flex based on the users input
 */
export const useExoticsFlexi = (bet: TBetSlipBetExotics) => {
  const [flexi, setFlexi] = useState<string>('0');
  const dispatch = useAppDispatch();
  const newBetID = bet?.id;
  const { updateBet: _updateBet } = getBetSlipStoreActions();

  const combos =
    bet.type === 'Exotics' && calculateCombosForToteMulti(bet.selection);

  useEffect(() => {
    if (!bet) return;

    const isNumber = !Number.isNaN(Number(bet.stake));

    if (!isNumber || String(bet.stake) === '0') {
      setFlexi('0');
    } else {
      setFlexi(
        isToteMulti(bet.subType as string)
          ? Number(((Number(bet.stake) / combos) * 100).toFixed(2))
          : String(
              calculateExoticFlexi(Number(bet.stake), bet.validSelectionAmount)
            )
      );
    }
  }, [bet, combos]);

  const updateStake = (stake: number) => {
    const updatedBet: TBetSlipBetExotics = {
      ...bet,
      stake,
      hasBeenRounded: false,
    };

    if (newBetID) {
      _updateBet({ id: newBetID, values: { stake: String(stake) } });
    }

    dispatch(updateBet(updatedBet));
  };

  const handleFlexiChange = (value: string | number) => {
    if (!value) {
      setFlexi('0');
      updateStake(0);
      return;
    }

    const val = validateStake(value);

    if (isToteMulti(bet.subType as string)) {
      setFlexi(val);
      updateStake(Number(((Number(val) * combos) / 100).toFixed(2)));
      return;
    }

    setFlexi(String(val));
    updateStake(+((val * bet.validSelectionAmount) / 100).toFixed(2));
  };

  return {
    flexi,
    handleFlexiChange,
  };
};

/**
 * NA
 */
export const useBetFormField = (bet: TBetSlipBet, fieldName: string) => {
  const dispatch = useAppDispatch();
  const { focusedBet, focusedBetField, viewMode, showFieldErrors } =
    useAppSelector((state) => state.betSlip);

  // Shown when the device is mobile, we are not in review and the current field is focused.
  // We manage focus globally via state as only one can be in focus at a time
  const isKeyboardShown =
    viewMode === EBetSlipViewMode.EditingBets &&
    focusedBet === bet.request_id &&
    focusedBetField === fieldName;

  // Trigger the keypad when we focus on this element
  const onTextInputFocus = () => {
    dispatch(setHasKeypad(true));

    dispatch(
      setBetSlipShowFieldErrors(
        showFieldErrors.filter((request_id) => request_id !== bet.request_id)
      )
    );

    dispatch(
      setBetFocused({
        field_name: fieldName,
        request_id: bet.request_id,
      })
    );
  };

  // Disable editing - show text when we are in review or the bet has been place
  // This will be extended down the track to be when the bet is in review
  const isReviewing = viewMode === EBetSlipViewMode.ReviewingBets;

  // Close the keypad if the user selects to
  const onKeypadDone = () => dispatch(setBetFocused(undefined));

  // Show the error if we are forced to globally OR we have interacted with the field and there is a valid error state
  const showFieldError = showFieldErrors.some(
    (request_id: string) => request_id === bet.request_id
  );

  return {
    isKeyboardShown,
    showFieldError,
    onKeypadDone,
    isReviewing,
    onTextInputFocus,
  };
};

/**
 * NA
 */
/* This function responsible to use bonus bets for a bet. */
export const useBonusBets = (bet?: TBetSlipBet) => {
  const newBetID = bet?.id;

  // Temp keeping both
  const betsLegacy = useAppSelector((state) => state.betSlip.bets);

  const betsNew = useBetSlipBets() ?? {};
  const _keys = Object.keys(betsNew);
  const betsTransformed = _keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  const bets = FEATURE_FLAGS.HAS_NEW_BS ? betsTransformed : betsLegacy;

  const dispatch = useAppDispatch();
  const { viewMode } = useAppSelector((state) => state.betSlip);

  const { bonusBalance } = usePunterBalance();
  const bonusBalanceDollars = Number(bonusBalance / 100); // Convert to dollars

  const { updateBet: _updateBet } = getBetSlipStoreActions();

  const isToteMultiBet = isToteMulti(bet?.subType as string);

  // Types that can use bonus bets
  const typesBonusBetsCanBeUsedOn =
    bet?.type === EBetSlipBetSubmissionType.Single ||
    bet?.type === EBetSlipBetSubmissionType.EachWay ||
    bet?.type === EBetSlipBetSubmissionType.Multi ||
    bet?.type === EBetSlipBetSubmissionType.SGMulti ||
    bet?.type === EBetSlipBetSubmissionType.SRMulti ||
    bet?.type === EBetSlipBetSubmissionType.Blended ||
    isToteMultiBet;

  const bonusBetsTotalAllocated = bets
    .filter((b) => b.is_bonus_bet && !b.confirmation)
    .reduce(
      (current, b) => current + (Number(b.stake) > 0 ? Number(b.stake) : 0),
      0
    );

  // Total bonus bets balance less already allocated to bets in slip
  const bonusBalanceAvailable =
    bonusBalanceDollars -
    bonusBetsTotalAllocated +
    // If bet is already marked as a bonus, include the stake amount in availability check
    (bet?.is_bonus_bet ? Number(bet.stake) : 0);

  // Check does the current bet stake >= bonus balance available
  const canBonusBetsBeUsed =
    !!bet?.stake &&
    Number(bet.stake) > 0 &&
    bonusBalanceAvailable >= Number(bet.stake);

  const displayBonusBetsButton: boolean =
    bet?.is_bonus_bet ||
    (canBonusBetsBeUsed &&
      typesBonusBetsCanBeUsedOn &&
      (viewMode === EBetSlipViewMode.EditingBets ||
        (viewMode === EBetSlipViewMode.ReviewingBets && bet.is_bonus_bet)));

  const toggleUseBonusBets = () => {
    if (bet) {
      const updatedBet: TBetSlipBet = {
        ...bet,
        is_bonus_bet: !bet.is_bonus_bet,
      };

      if (newBetID) {
        _updateBet((state) => {
          const _bets = { ...state };
          _bets[newBetID].isBonusBet = !_bets[newBetID].isBonusBet;
          return _bets;
        });
      }

      dispatch(updateBet(updatedBet));
    }
  };

  return { displayBonusBetsButton, toggleUseBonusBets };
};

/**
 * NA
 */
export const useBetSpecificViewMode = (bet?: TBetSlipBet) => {
  const { viewMode } = useAppSelector((state) => state.betSlip);
  const { displayBonusBetsButton } = useBonusBets(bet);

  const getBetIsInReview = (): boolean | undefined => {
    if (viewMode === EBetSlipViewMode.ReviewingBets) return true;
    if (
      ['Multi', 'SGMulti', 'SRMulti'].includes(bet?.type ?? '') &&
      bet?.confirmation?.rejection_reason ===
        EBetSubmissionRejectionReason.PropositionClosed
    )
      return false;

    return (
      bet?.confirmation &&
      bet?.confirmation.rejection_reason !==
        EBetSubmissionRejectionReason.OddsChange &&
      bet?.confirmation.rejection_reason !==
        EBetSubmissionRejectionReason.InsufficentFund
    );
  };

  const isToteMultiBet = isToteMulti(bet?.subType as string);

  const displayBonusBetEstReturnFlex =
    (['Single', 'SGMulti', 'SRMulti', 'Blended'].includes(bet?.type ?? '') ||
      isToteMultiBet) &&
    (viewMode === EBetSlipViewMode.EditingBets || displayBonusBetsButton);

  return { betIsInReview: getBetIsInReview(), displayBonusBetEstReturnFlex };
};

/**
 * NA
 */
type UseBetSlipFooterProps = {
  bets: TBetSlipBet[];
};

export const useBetSlipFooter = ({ bets }: UseBetSlipFooterProps) => {
  const { isAuthenticated } = useAuth();
  const moMBet = useMoMBet();
  const { accountBalance, bonusBalance } = usePunterBalance();
  const { updateBet: _updateBet, updateMoMBet } = getBetSlipStoreActions();

  const dispatch = useAppDispatch();

  const themeName = getThemeName();

  const {
    potentialReturns,
    viewMode,
    submissionLoading,
    hasSufficientFunds,
    displayOddsChangeBanner,
    displayEventsClosedBanner,
    updateConfirmFlag,
  } = useAppSelector((state) => state.betSlip);

  useEffect(() => {
    if (viewMode === EBetSlipViewMode.EditingBets) {
      if (updateConfirmFlag) {
        dispatch(setUpdateConfirmFlag(false));
      }
      if (displayOddsChangeBanner) {
        dispatch(setDisplayOddsChangeBanner(false));
      }
      if (displayEventsClosedBanner) {
        // if the punter receives a 'props closed' notification for a multi,
        // we display banners until they go back and edit their bets
        // or resubmit
        dispatch(clearClosedEventBets());
        dispatch(setDisplayEventsClosedBanner(false));
      }
    }
  }, [
    dispatch,
    displayEventsClosedBanner,
    displayOddsChangeBanner,
    viewMode,
    updateConfirmFlag,
  ]);

  // hasSufficientFunds is set to false in submitBetSlip if any bets come back with an InsufficientFund rejection
  // If that happens, every time the stake is updated we check to see if we can reupdate hasSufficientFunds
  useEffect(() => {
    const relevantBets = bets?.filter(
      (bet) =>
        !bet.confirmation ||
        (bet.confirmation &&
          (bet.confirmation.rejection_reason ===
            EBetSubmissionRejectionReason.OddsChange ||
            bet.confirmation.rejection_reason ===
              EBetSubmissionRejectionReason.InsufficentFund))
    );
    const totalStake = relevantBets.reduce(
      (acc, bet) => acc + (bet.stake ? Number(bet.stake) : 0),
      0
    );
    const totalBonusBetStake = relevantBets
      .filter((bet) => bet.is_bonus_bet)
      .reduce((acc, bet) => acc + Number(bet.stake ?? 0), 0);
    const totalNonBonusBetStake = totalStake - totalBonusBetStake;
    if (
      !hasSufficientFunds &&
      accountBalance / 100 >= totalNonBonusBetStake &&
      bonusBalance / 100 >= totalBonusBetStake
    ) {
      dispatch(setHasSufficientFunds(true));
    }
  }, [hasSufficientFunds, accountBalance, bets, bonusBalance, dispatch]);

  // If any bet is a) an exotic, or b) an SP bet
  const noEstReturns = bets?.some(
    (bet) =>
      bet.type === EBetSlipBetSubmissionType.Exotics ||
      bet.price_type === 'starting' ||
      bet.price_type === 'tote_single_mid' ||
      bet.price_type === 'tote_single_best'
  );

  const [{ BetSlip: Strings }] = getStrings();

  const {
    betsToUpdateAndConfirmCount,
    allBetsPlaced,
    hasEditableBets,
    hasClearableBets,
    lessThanTwoEventsLeftOpen,
  } = useBetsCount({ bets });

  const submitButtonTitle = () => {
    if (!isAuthenticated)
      return (
        <>
          <Icon as={LogIn} boxSize="6" pr="1" />
          {Strings.betSlipModal.login}
        </>
      );
    if (betsToUpdateAndConfirmCount && updateConfirmFlag)
      return `${Strings.betSlipModal.updateConfirmBets} (${betsToUpdateAndConfirmCount})`;

    if ((!hasEditableBets && hasClearableBets) || lessThanTwoEventsLeftOpen) {
      return Strings.betSlipModal.done;
    }

    if (viewMode === EBetSlipViewMode.EditingBets) {
      const icon = [EThemesOG.Betprofessor, EThemesOG.Sterlingparker].includes(
        themeName
      )
        ? ArrowDownCircleFill
        : ArrowDownCircle;
      return (
        <>
          <Icon as={icon} boxSize="6" pr="1" />
          {Strings.betSlipModal.placeBets}
        </>
      );
    }

    return Strings.betSlipModal.confirmBets;
  };

  const clickClearBets = () =>
    dispatch(setBetSlipViewMode(EBetSlipViewMode.EmptyBets));

  const clickEditBets = () => {
    dispatch(clearActionedBets());
    _updateBet((store) => updateNonActionedBets(store));
    dispatch(setBetSlipViewMode(EBetSlipViewMode.EditingBets));
    if (moMBet?.betSlipStatus === EBetSlipViewMode.ReviewingBets)
      updateMoMBet({ betSlipStatus: EBetSlipViewMode.EditingBets });

    // Clear review status
    dispatch(setBetSlipViewMode(EBetSlipViewMode.EditingBets));
  };

  const numberOfBetsWithStake =
    bets
      ?.filter((bet) => bet.confirmation?.status !== 'Placed')
      .filter((bet) => Number(bet.stake) > 0).length +
    // eslint-disable-next-line no-nested-ternary
    (moMBet ? (moMBet?.stake !== '' ? 1 : 0) : 0);

  const isConfirmButtonDisabled =
    hasEditableBets && numberOfBetsWithStake === 0;

  return {
    clickClearBets,
    clickEditBets,
    submitButtonTitle,
    noEstReturns,
    potentialReturns,
    allBetsPlaced,
    viewMode,
    submissionLoading,
    hasSufficientFunds,
    hasEditableBets,
    hasClearableBets,
    isConfirmButtonDisabled,
  };
};

/**
 * NA
 */
export const useDomReady = () => {
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  return {
    domReady,
  };
};

const CHECK_LOCAL_STORAGE_INTERVAL = 3_000;

/**
 * NA
 */
export const useBetRequests = () => {
  // Temp keeping both
  const { bets: betsLegacy, bonusChance } = useAppSelector(
    (state) => state.betSlip
  );

  const betsNew = useBetSlipBets() ?? {};
  const moMBet = useMoMBet();
  const _keys = Object.keys(betsNew);
  const betsTransformed = _keys.map(
    (k) => transformBetForLegacy(betsNew[k]) as unknown as TBetSlipBet
  );
  const bets = FEATURE_FLAGS.HAS_NEW_BS ? betsTransformed : betsLegacy;

  const { isAuthenticated } = useAuth();
  const [raceIds, setRacesIds] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { updateBet: _updateBet, updateMoMBet } = getBetSlipStoreActions();

  const { data } = useQuery(
    [keys.betRequests, isAuthenticated, raceIds],
    () =>
      queryPunterBetRequests({
        requests: [...raceIds, ...(moMBet ? [moMBet.requestId] : [])].join(','),
      }),
    {
      enabled: (!!isAuthenticated && !!raceIds.length) || !!moMBet,
      refetchInterval: 3000,
      staleTime: 0,
      onSuccess(res) {
        const raceIdsInStorage = JSON.parse(
          localStorage.getItem('@@/BC-RACE_IDS') ?? '[]'
        );
        const pendingBets: TPunterBetRequest[] = (
          res?.records ?? ([] as any)
        ).filter(
          (r: TPunterBetRequest) => r?.status === EBetRequestStatus.Pending
        );
        const commonRaceIds = (pendingBets ?? []).filter((r) =>
          raceIdsInStorage.includes(r?.request_id as string)
        );

        const matchingBetIds = res?.records
          ?.filter((bet) =>
            bets?.some((zBet) => bet?.request_id === zBet.request_id)
          )
          .map((s) => s?.request_id);

        if (res?.records?.length) {
          bets?.forEach((bet) => {
            const betRequest = data?.records?.find(
              (req) => req?.request_id === bet.request_id
            );
            if (!betRequest) {
              return;
            }
            const matchedStatus = findConfirmationStatus(betRequest?.status);

            if (matchedStatus !== bet.confirmation?.status) {
              const rejectionReason =
                matchedStatus === EBetSubmissionConfirmationStatus.Rejected
                  ? EBetSubmissionRejectionReason.ManualRejection
                  : undefined;

              const updatedBet: TBetSlipBet = {
                ...bet,
                confirmation: {
                  ...bet.confirmation,
                  status: matchedStatus,
                  rejection_reason: rejectionReason,
                },
              };

              const eventId =
                (bet as TBetSlipBetSingle).race_id ?? bet.event_id;
              const betLegsData = betRequest.bet_legs?.find(
                (leg) => leg?.event_id === eventId
              )?.event_data;

              const firstLeg = betRequest.proposition_info?.[0];
              const mbRollover = betRequest.bet_legs?.[1];

              const eventData = betLegsData
                ? {
                    proposition_type: betLegsData.proposition_type,
                    runner_name: betLegsData.runner_name,
                    runner_number: betLegsData.runner_number,
                    venue_name: betLegsData.venue_name,
                    race_name: betLegsData.race_name,
                    race_number: betLegsData.race_number,
                    barrier_number: betLegsData.runner_barrier_number,
                  }
                : {
                    proposition_type: firstLeg?.proposition_type,
                    runner_name: firstLeg?.proposition_name,
                    runner_number: firstLeg?.runner_number,
                    venue_name: firstLeg?.venue_name,
                    race_name: firstLeg?.race_name,
                    race_number: firstLeg?.race_number,
                    barrier_number: firstLeg?.barrier_number,
                  };

              // TODO: Clean up
              const legInfos = [
                {
                  proposition_type: eventData.proposition_type ?? '',
                  leg_num: 1,
                  barrier_number: eventData.barrier_number ?? 0,
                  venue_name: eventData?.venue_name ?? '',
                  price_type: 'mystery_bet',
                  race_number: eventData?.race_number ?? 0,
                  proposition_name: eventData.runner_name ?? '',
                  runner_number: eventData.race_number ?? 0,
                  race_name: eventData.race_name ?? '',
                },
              ];

              if (mbRollover)
                legInfos.push({
                  proposition_type:
                    mbRollover?.event_data.proposition_type ?? '',
                  leg_num: 2,
                  barrier_number:
                    mbRollover?.event_data.runner_barrier_number ?? 0,
                  venue_name: mbRollover?.event_data.venue_name ?? '',
                  price_type: 'mystery_bet',
                  race_number: mbRollover?.event_data.race_number ?? 0,
                  proposition_name: mbRollover?.event_data.runner_name ?? '',
                  runner_number: mbRollover?.event_data.runner_number ?? 0,
                  race_name: mbRollover?.event_data.race_name ?? '',
                });

              /**
               * if statement can be removed as updatedBet will always be
               * an object.
               */
              if (updatedBet) {
                let haveOddsIncreased = false;
                if (betRequest.approved_odds > bet.odds) {
                  haveOddsIncreased = true;
                }
                // @ts-ignore
                const newBetID = bet?.id;

                if (newBetID) {
                  _updateBet((state) => {
                    const _bets = { ...(state ?? {}) };
                    _bets[newBetID] = {
                      ..._bets[newBetID],
                      odds:
                        betRequest.approved_odds ?? betRequest.requested_odds,
                    };
                    _bets[newBetID].confirmation = {
                      ..._bets[newBetID].confirmation,
                      proposition_info: legInfos,
                      status: matchedStatus,
                      rejection_reason: rejectionReason,
                      haveOddsIncreasedAfterSubmit: haveOddsIncreased,
                    };
                    return _bets;
                  });
                }

                if (bet.price_type === 'mystery_bet') {
                  const singleUpdatedBet: TBetSlipBetSingle = {
                    ...(updatedBet as TBetSlipBetSingle),
                    confirmation: {
                      ...updatedBet.confirmation,
                      status: EBetSubmissionConfirmationStatus.Placed,
                      proposition_info: [
                        {
                          proposition_type: eventData.proposition_type ?? '',
                          leg_num: 1,
                          barrier_number: eventData.barrier_number ?? 0,
                          venue_name: eventData?.venue_name ?? '',
                          price_type: 'mystery_bet',
                          race_number: eventData?.race_number ?? 0,
                          proposition_name: eventData.runner_name ?? '',
                          runner_number: eventData.race_number ?? 0,
                          race_name: eventData.race_name ?? '',
                        },
                      ],
                    },
                  };

                  dispatch(updateBet(singleUpdatedBet));
                } else {
                  dispatch(updateBet(updatedBet));
                }

                const raceIdsAsArray = commonRaceIds
                  .map((r) => r?.request_id as string)
                  .filter(Boolean);

                localStorage.setItem(
                  '@@/BC-RACE_IDS',
                  JSON.stringify(matchingBetIds)
                );
                setRacesIds(raceIdsAsArray);

                Promise.all([
                  queryClient.refetchQueries([keys.betsForRace]),
                ]).catch(() => undefined);
              }
            }
          });

          if (moMBet) {
            const { requestId } = moMBet;
            const record = res?.records?.find(
              ({ request_id }) => request_id === requestId
            );

            if (record) {
              if (record.status) {
                const { status } = record;
                switch (status) {
                  case EBetRequestStatus.Pending: {
                    updateMoMBet({
                      betSubmissionStatus:
                        EBetSubmissionConfirmationStatus.Pending,
                      betRejectionReason: null,
                    });
                    break;
                  }
                  case EBetRequestStatus.Approved: {
                    updateMoMBet({
                      betSubmissionStatus:
                        EBetSubmissionConfirmationStatus.Placed,
                      betRequestStatus: EBetRequestStatus.Approved,
                      betRejectionReason: null,
                      odds: record.approved_odds ?? record.requested_odds,
                      oddsIncreased: record.approved_odds > moMBet.odds,
                    });
                    break;
                  }
                  case EBetRequestStatus.Rejected: {
                    updateMoMBet({
                      betSubmissionStatus:
                        EBetSubmissionConfirmationStatus.Rejected,
                    });
                    break;
                  }
                  /**
                   * For a reduced stake, MBL only applies to punter categories SHARP and SINGLES
                   * Multi bets are not eligible for those punter categories we shouldn't expect a
                   * rejection reason to be ReducedStake.
                   */
                  case EBetRequestStatus.ReducedStake: {
                    const {
                      approved_stake: approvedStake,
                      requested_stake: requestedStake,
                    } = record;

                    const reducedStakeInfo = {
                      approvedStake: centsToDollars(approvedStake),
                      rejectedStake: centsToDollars(
                        requestedStake - approvedStake
                      ),
                    };

                    updateMoMBet({
                      stake: (approvedStake / 100).toFixed(2),
                      reducedStakeInfo,
                      betSubmissionStatus:
                        EBetSubmissionConfirmationStatus.Placed,
                      betRequestStatus: EBetRequestStatus.ReducedStake,
                    });
                    break;
                  }
                  default: {
                    throw new Error(
                      `Confirmation status unsupported: ${status}`
                    );
                  }
                }
              }
            }
          }
        }
      },
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const raceIdsInStorage: string[] = JSON.parse(
        localStorage.getItem('@@/BC-RACE_IDS') ?? '[]'
      );

      setRacesIds(raceIdsInStorage);
    }, CHECK_LOCAL_STORAGE_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!bonusChance) {
      queryClient
        .invalidateQueries([keys.punterAccountOverview])
        .catch(() => undefined);
    }
  }, [bonusChance, data, queryClient]);

  return {
    isAuthenticated,
    data,
  };
};

/**
 * DONE
 * @deprecated setBet replaces this
 */
export const useSRMultiBet = () => {
  const dispatch = useAppDispatch();
  const { supportedBets } = useSupportedBets();
  const intl = useIntl();
  const { data: punterProfile } = useQueryPunterAccount();

  type TAddtoBetSlipType = {
    race: TRaceMeta;
    market: TMarket;
    venue: TVenue;
    placedSelections: any;
    stake?: number;
    selectionOdds: number;
  };

  const addToBetSlip = ({
    race,
    market,
    venue,
    placedSelections,
    stake,
    selectionOdds,
  }: TAddtoBetSlipType) => {
    if (
      !race?.race_id ||
      !market?.propositions ||
      !market?.propositions[0] ||
      !race?.start_time ||
      !market?.market_name
    ) {
      toast.error(
        intl.formatMessage({ id: 'betslip.betslipbetcard.srm.error' })
      );
      return;
    }
    if (supportedBets && supportedBets.srmulti === false) {
      toast.error(intl.formatMessage({ id: 'generic.betTypeNotSupported' }));
      return;
    }
    if (!placedSelections.length && !placedSelections) return;

    const proposition = market?.propositions[0];

    if (
      !proposition ||
      !proposition?.proposition_id ||
      !proposition?.proposition_name
    ) {
      toast.error(
        intl.formatMessage({ id: 'betslip.betslipbetcard.srm.error' })
      );
      return;
    }

    const SRMultiBet: TBetSlipBetSRMulti = {
      ...generateNewBetSlipBet(EBetSlipBetSubmissionType.SRMulti, stake),
      race_id: race.race_id,
      selection: placedSelections,
      event_icon: race.race_type,
      event_type: EEventType.Race,
      event_market_name: '',
      event_start_time: race?.start_time ?? '',
      event_title: race.display_name ?? '',
      event_subtitle: `${venue?.venue_display_name ?? ''} R${
        race?.race_number ?? ''
      }`,
      odds: selectionOdds,
      event_data: {
        race_meeting_date: race?.meeting_date,
        race_number: race?.race_number,
        venue_name: venue?.venue_name,
        venue_id: venue?.venue_id,
      },
      event_id: race?.race_id,
    };

    dispatch(addBet({ bet: SRMultiBet }));

    if (!IS_MOBILE_APP) {
      trackEvent('addToBetSlip', {
        punterId: punterProfile?.punter_id,
      });

      dispatch(setBetSlipOpen(true));
    }
  };

  return { addToBetSlip };
};

/**
 * DONE
 * @deprecated setBet replaces this
 */
export const useBlendedBet = () => {
  const dispatch = useAppDispatch();
  const { supportedBets } = useSupportedBets();
  const intl = useIntl();
  const { data: punterProfile } = useQueryPunterAccount();

  type TAddtoBetSlipType = {
    race: TRaceMeta;
    venue: TVenue;
    placedSelections: any;
    stake?: number;
    selectionOdds: number;
  };

  const addToBetSlip = ({
    race,
    venue,
    placedSelections,
    stake,
    selectionOdds,
  }: TAddtoBetSlipType) => {
    if (!race?.race_id || !race?.start_time) {
      toast.error(
        intl.formatMessage({ id: 'betslip.betslipbetcard.srm.error' })
      );
      return;
    }
    if (supportedBets && supportedBets.multi === false) {
      toast.error(intl.formatMessage({ id: 'generic.betTypeNotSupported' }));
      return;
    }
    if (!placedSelections && !placedSelections.length) return;

    const BlendedBet: TBlenededBetMulti = {
      ...generateNewBetSlipBet(EBetSlipBetSubmissionType.Blended, stake),
      race_id: race.race_id,
      selection: placedSelections,
      event_icon: race.race_type,
      event_type: EEventType.Race,
      event_market_name: '',
      event_start_time: race?.start_time ?? '',
      event_title: race.display_name ?? '',
      event_subtitle: `${venue?.venue_display_name ?? ''} R${
        race?.race_number ?? ''
      }`,
      odds: selectionOdds,
      event_data: {
        race_meeting_date: race?.meeting_date,
        race_number: race?.race_number,
        venue_name: venue?.venue_name,
        venue_id: venue?.venue_id,
      },
      event_id: race?.race_id,
    };

    // FIXME: why did i need to add any here?
    dispatch(addBet({ bet: BlendedBet as any }));

    if (!IS_MOBILE_APP) {
      trackEvent('addToBetSlip', {
        punterId: punterProfile?.punter_id,
      });

      dispatch(setBetSlipOpen(true));
    }
  };

  return { addToBetSlip };
};

/**
 * DONE
 * @deprecated setBet replaces this
 */
export const useAddSgmToBetSlip = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { supportedBets } = useSupportedBets();
  const { sport, multi, sgmulti } = supportedBets ?? {};

  const addSgmToBetSlip = (
    selections: TExtendedProposition[],
    odds?: number,
    match?: TMatch
  ) => {
    if (!selections.length || !match?.match_id || !match?.match_start_time) {
      toast.error(intl.formatMessage({ id: 'generic.erroroccurred' }));
      return;
    }

    if (sport === false || sgmulti === false) {
      toast.error(intl.formatMessage({ id: 'generic.betTypeNotSupported' }));
      return;
    }

    const bet: TBetSlipBetSGMulti = {
      ...generateNewBetSlipBet(EBetSlipBetSubmissionType.SGMulti),
      event_id: match.match_id,
      event_start_time: match.match_start_time,
      event_market_name: '',
      event_icon: match.sport_name,
      event_title: '',
      event_subtitle: match.match_name ?? '',
      proposition_id: '',
      event_type: EEventType.Match,
      event_data: {
        competition_name: match.competition_name,
        competition_id: match.competition_id,
        sport_id: match.sport_id,
        match_name: match.match_name,
      },
      selections,
      odds,
    };

    dispatch(addBet({ bet, multisSupported: multi }));
    dispatch(setBetSlipOpen(true));

    trackEvent('addToBetSlip');
  };

  return addSgmToBetSlip;
};

/**
 * DONE
 * @deprecated setBet replaces this
 */
export const useAddEvenShotToBetSlip = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { data: supportedBets } = useQuerySupportedBets();
  const { racing, even_shot: evenShot } = supportedBets ?? {};

  const addEvenShotToBetSlip = (
    evenShotData: TEvenShotResponse | undefined,
    market: string,
    runnerName: string,
    race?: TRaceMeta
  ) => {
    if (!evenShotData || !race?.race_id || !race.start_time) {
      toast.error(intl.formatMessage({ id: 'generic.erroroccurred' }));
      return;
    }
    const { proposition_id: propId, price: odds } = evenShotData;
    if (!racing || !evenShot) {
      toast.error(intl.formatMessage({ id: 'generic.betTypeNotSupported' }));
      return;
    }
    const bet: TBetSlipBetSingle = {
      ...generateNewBetSlipBet(EBetSlipBetSubmissionType.Single),
      proposition_id: propId,
      event_start_time: race.start_time,
      event_market_name: market,
      event_title: runnerName,
      event_subtitle: `${race.display_name} R${race.race_number}`,
      odds,
      price_type: 'even_shot',
      event_icon: race.race_type,
    };
    dispatch(addBet({ bet }));
    dispatch(setBetSlipOpen(true));
  };

  return { addEvenShotToBetSlip };
};

export const useAddMysteryBetToBetSlip = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { data: supportedBets } = useQuerySupportedBets();
  const { racing, mystery_bet: mysteryBet } = supportedBets ?? {};

  const addMysteryBetToBetSlip = (
    selectedProp: TMysteryBet,
    mysteryBetData: TMysteryBetData | undefined,
    venueName: string,
    runnerName: string,
    race?: TRaceMeta
  ) => {
    if (!mysteryBetData || !race?.race_id || !race.start_time) {
      toast.error(intl.formatMessage({ id: 'generic.erroroccurred' }));
      return;
    }
    if (!racing || !mysteryBet) {
      toast.error(intl.formatMessage({ id: 'generic.betTypeNotSupported' }));
      return;
    }

    const rolloverRaceIds = mysteryBetData.bets.flatMap(
      (bet) => bet.rollovers?.map((s) => ({ race_id: s.race_id })) ?? []
    );

    const bet: TBetSlipBetMystery = {
      ...generateNewBetSlipBet(EBetSlipBetSubmissionType.Single),
      event_start_time: race.start_time,
      event_market_name: `${venueName} R${race.race_number}`,
      event_title: runnerName,
      event_subtitle: `${race.display_name} R${race.race_number}`,
      odds: selectedProp.price,
      price_type: 'mystery_bet',
      race_id: race.race_id,
      event_icon: race.race_type,
      rollovers: rolloverRaceIds.length > 0 ? rolloverRaceIds : undefined,
      mystery_bet: mysteryBetData,
    };

    dispatch(addBet({ bet }));
    dispatch(setBetSlipOpen(true));
  };

  return { addMysteryBetToBetSlip };
};
