import { CSSObject } from '@chakra-ui/react';
import background from '@/assets/titanbet/images/race-bg.png';

export const BoxResultedWrapper: CSSObject = {
  mb: '0',
  p: '3',
  borderRadius: 'lg',
  flex: '1',
  bgImage: `url(${background})`,
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25),0px 0px 0px 2px #C19262 inset, 0px 0px 0px 5px #1B1E1F inset, 0px 0px 0px 6px rgba(255, 255, 255, 0.07) inset',
};

export const RaceResultsHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'accent',
  fontWeight: 'normal'
};

export const RunnerResultRow: CSSObject = {
  borderTop: '1px dashed rgba(255, 255, 255, 0.15)',
  borderBottom: '1px dashed rgba(0, 0, 0, 0.15)',
  py: '1.5',
  h: '12',
  _last: {
    borderBottom: 'none',
  },
};

export const RunnerDetails: CSSObject = {
  gap: '2',
};

export const RunnerPlace: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.200, gamma.400)',
  color: 'gamma.800',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  textTransform: 'uppercase',
  padding: '2',
  fontSize: '2xs',
  '&&': {
    h: '7',
    w: '9',
  },
  border: 'none',
};

export const RunnerNumber: CSSObject = {
  fontWeight: 'normal',
  fontSize: '2xs',
  color: 'white',
  pos: 'relative',
  bottom: '2px',
};

export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};

export const RunnerOdds: CSSObject = {
  w: '46px',
  h: '8',
  bg: 'blackAlpha.400',
  border: 'none',
  borderRadius: 'md',
  color: 'alpha.300',
  fontWeight: 'black',
  fontFamily: 'Roboto',
  fontSize: 'xs',
};

export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  pb: '2',
  alignItems: 'center',
};

export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'bold',
};

export const FlexEventRule: CSSObject = {
  pt: '2',
  color: 'white',
};
