import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'solid',
  height: '40px',
};

export const FlexWrapper: CSSObject = {
  bg: '#2F2F2F',
  borderBottomRadius: 'lg',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
  },

  '&&': {
    '.chakra-form__label': {
      color: 'white',
    },
    '.chakra-input__group': {
      border: 'none',
      boxShadow: 'none',
      p: '0',
    },
    '.chakra-input__left-addon': {
      bg: ' beta.400',
      h: 'auto',
      border: 'none',
      fontWeight: 'bold',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  bg: '#2F2F2F',
  borderX: '1px',
  borderY: 'none',
  borderBottomRadius: 'lg',
  zIndex: '10',
};

export const InfoHeader: CSSObject = {
  borderTop: '3px solid',
  borderColor: 'beta.400',
  bg: '#3E3E3E',
  h: '9',
  px: '4',
  py: '3',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'beta.400',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'uppercase',
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
};
