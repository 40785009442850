import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'lg',
    my: 2,
    px: '4'
  },
  heading: {
    color: 'white',
    fontWeight: 'semibold',
    fontSize: 'sm',
    mt: '-2',
  },
  promotionWrapper: {
    bg: 'beta.600',
    my: '3',
    boxShadow:
      ' 0px 0px 0px 1px rgba(74, 84, 84, 1) inset,  0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'md',
    sx: {
      img: {
        borderTopRadius: 'md',
        pt: '1px',
        px: '1px',
      },
    },
  },
  flexWrapper: {
    gap: '4',
    p: '3',
    justifyContent: 'space-between',
    mx: '1px',
  },
  termsAndConditions: {
    color: 'white',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
  },
};
