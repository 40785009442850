import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: 0,
  mb: 0,
  mt: 0,
  borderRadius: 'lg',
  bg: 'rgba(129, 83, 255, 0.50);',
  boxShadow:
    '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset',
  backdropFilter: 'blur(2.5px)',

  '&&&': { mb: 0 },
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  textShadow: '0px 0px 8px rgba(67, 187, 255, 0.50)',
};

export const ButtonViewMore: CSSObject = {
  // bg: 'beta.400',
  mr: '0',
  color: 'gamma.300',
  textAlign: 'center',
  fontSize: 'sm',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
  textTransform: 'uppercase',

  width: 'full',
  border: 'none',
  boxShadow: 'none',

  bg: 'transparent',

  _hover: {
    bgGradient: 'none',
  },
};
